import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { URLRoutes } from "./routes";


export default function App({ store, persistor, basename }) {
    return (
        <Provider store={store}>
            <React.Suspense>
                <BrowserRouter basename={basename}>
                    <URLRoutes />
                </BrowserRouter>
            </React.Suspense>
        </Provider>
    );
}
