import React, { useEffect, useState } from "react";
import { Redirect, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Home from "./app/modules/pages/home/home";
import Cadastro1 from "./app/modules/pages/cadastro/cadastro1/Cadastro1";
import Cadastro2 from "./app/modules/pages/cadastro/cadastro2/Cadastro2";
import Cadastro3 from "./app/modules/pages/cadastro/cadastro3/Cadastro3";
import Cadastro4 from "./app/modules/pages/cadastro/cadastro4/Cadastro4";
import { useNavigate } from 'react-router-dom'

export function URLRoutes() {
  // const fluxoConsorcio = useSelector((state) => state)
  // const [dado, setDado] = useState(0);
  // const navigate = useNavigate();
  
  // useEffect(() => {
  //   if (fluxoConsorcio.consorciosCocaCola.passo === 1) {
  //     navigate('/adesao/dados-consorcio')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 2) {
  //     navigate('/adesao/documento-consorcio')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 3) {
  //     navigate('/adesao/termos')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 4) {
  //     navigate('/adesao/obrigado')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 0) {
  //     navigate('/')
  //   }
  // }, [fluxoConsorcio])

  var cocacolaId = document.getElementById('cocacola');

  useEffect(() => {
    window.onload = function () {
      cocacolaId.href = "/images/ideas.svg";
    }
  }, [window.onload])

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/adesao/dados-consorcio" element={<Cadastro1 />} />
      <Route exact path="/adesao/documento-consorcio" element={<Cadastro2 />} />
      <Route exact path="/adesao/termos" element={<Cadastro3 />} />
      <Route exact path="/adesao/obrigado" element={<Cadastro4 />} />
    </Routes>
  );
}