//Importação React
import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Voltar, Finalizar, EnviarInscricao } from "../../../../../store";
import numeral from "../../../../../components/control/numeralBRL";

import "../cadastro.css";
import { termoAdesaoSimulacaoLead } from "../../../../../pages/api/simulacaoLead";
import { Input } from "../../../../../components/control/Input";
import EconomiaTotal from "../components/EconomiaTotal";

const AdesaoFormTermoSchema = Yup.object().shape({
  termoAdesao: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
});

export default function Cadastro3() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fluxoConsorcio = useSelector((state) => state);

  const idLeadSimulacao = fluxoConsorcio.idLead;

  const [checked, setChecked] = useState(false);

  const [valor1Mes, setValor1Mes] = useState(0);
  const [valor12Mes, setValor12Mes] = useState(0);
  const [valorSemDesconto, setValorSemDesconto] = useState(0);
  const [idLeadLocal, setIdLead] = useState(0);

  useEffect(() => {
    var valormes1 = localStorage.getItem("valor1Mes");
    var valormes12 = localStorage.getItem("valor12Mes");
    var valorSemDesconto = localStorage.getItem("valorDesconto");
    var idLead = localStorage.getItem("idLeadTela");

    var formatado1 = parseFloat(valormes1);
    var formatado2 = parseFloat(valormes12);
    var formatado3 = parseFloat(valorSemDesconto);
    var formatado4 = parseInt(idLead);

    const wizard = localStorage.getItem("wizard");

    if (wizard !== "true") {
      window.location.href = "https://energiafacil.com.br/";
    }

    setValor1Mes(formatado1);
    setValor12Mes(formatado2);
    setValorSemDesconto(formatado3);
    setIdLead(formatado4);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          termoAdesao: false,
        }}
        validationSchema={AdesaoFormTermoSchema}
        onSubmit={(values) => {
          if (values.termoAdesao == true) {
            var valor = (values.termoAdesao = "V");
            termoAdesaoSimulacaoLead(idLeadLocal, valor).then(() => {
              navigate("/adesao/obrigado");
            });
          }
        }}
      >
        {({ handleSubmit, errors, setFieldValue, values, touched }) => (
          <>
            <header
              className="header p-3 d-flex align-items-center"
              id="cadastro3"
            >
              <div className="ms-3 me-4">
                <a href="/">
                  <img
                    src="/images/energiaFacil.svg"
                    alt="Logo Energia Fácil"
                    style={{ width: "250px" }}
                  />
                </a>
              </div>
              <div class="progress_3">
                <div class="progress_bar_3"></div>
              </div>
            </header>
            <main class="cadastro_background">
              <section
                class="container cadastro"
                style={{ paddingBottom: "135px" }}
              >
                <div class="row justify-content-center">
                  <div class="card cadastro_fatura col-xl-7 col-11 col-sm-12">
                    <div
                      class="card-body"
                      style={{ overflow: "auto", height: "45rem" }}
                    >
                      <h3 class="termos_titulo">Termos de uso</h3>
                      <p className="paragraph_termo">
                        O presente Termo estabelece as obrigações que irão reger
                        a relação entre o site matogrossoenergia.com.br e seus
                        Usuários, aqui definidos como as pessoas naturais que
                        acessam o referido site (individualmente referidos como
                        Usuário). Seu acesso e utilização representa sua
                        aceitação integral e incondicional aos Termos de Uso. A
                        Política de Privacidade publicada no site integra estes
                        Termos de Uso. <br />
                        <br />
                        <center>
                          <strong>TERMOS DE USO</strong>
                        </center>{" "}
                        <br />
                        Este Website é controlado e operado por{" "}
                        <strong>CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL</strong>
                        , inscrito no CNPJ/ME sob nº 36.581.069/0001-01, com
                        sede na Avenida dos Florais, 1788, Sala 06, Ribeirão do
                        Lipa, CEP 78.049-520, Cuiabá/MT, (“CONSÓRCIO MATO
                        GROSSO”) <br />
                        <br />
                        <strong>1.USO E CONTEÚDO DO WEBSITE</strong> <br />
                        <br /> Salvo disposição em contrário, todos os direitos,
                        inclusive autorais, sobre o conteúdo das páginas deste
                        website, incluindo banco de dados, marcas, registradas
                        ou em processo de registro, textos, gráficos, ícones,
                        designs, cores, layout, serviços, informações,
                        materiais, instrumentos, organização de páginas,
                        desenhos e demais elementos contidos no presente website
                        são de titularidade exclusiva ou licenciados para o
                        CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL. A reprodução ou
                        imitação, o armazenamento, distribuição ou comunicação
                        ao público do conteúdo deste website é proibido sem o
                        prévio consentimento, por escrito, do CONSÓRCIO MATO
                        GROSSO ENERGIA RENOVÁVEL. A reprodução ou imitação total
                        ou parcial, mesmo que não literal, publicação ou
                        comunicação ao público de qualquer elemento deste
                        website sem o consentimento expresso do CONSÓRCIO MATO
                        GROSSO ENERGIA RENOVÁVEL sujeitará ao infrator às
                        medidas legais cabíveis.
                        <br />
                        <br />
                        <strong> 2.INFORMAÇÕES SOBRE O WEBSITE</strong> <br />
                        <br />
                        Em nosso Website é possível haver a disponibilização de
                        informações, textos, dados, imagens, fotografias,
                        vídeos, sons, ilustrações, nome, logos, marcas e
                        informações diversas sobre o CONSÓRCIO MATO GROSSO
                        ENERGIA RENOVÁVEL e suas práticas em sua área de atuação
                        (“Informações”). Os textos informativos produzidos pelo
                        time do CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL são de
                        exclusiva responsabilidade dos respectivos autores e
                        devem ser considerados apenas como material de
                        informação e não devem ser considerados como orientação
                        legal de nenhuma natureza. Todo material incluído neste
                        Website é publicado somente para fins informativos, não
                        constituindo material publicitário com a finalidade de
                        captação de clientela. O CONSÓRCIO MATO GROSSO ENERGIA
                        RENOVÁVEL, poderá, a qualquer momento e a seu exclusivo
                        critério, modificar as configurações deste website, sem
                        necessidade de qualquer prévia notificação aos Usuários.
                        O uso deste website após as alterações significará que o
                        Usuário aceita e concorda automaticamente com as
                        referidas mudanças. <br />
                        <br />
                        <strong>3.WEBSITES DE TERCEIROS</strong> <br />
                        <br />O Website do CONSÓRCIO MATO GROSSO ENERGIA
                        RENOVÁVEL poderá conter links para conveniência dos seus
                        Usuários, no entanto, declaramos que não temos qualquer
                        tipo de controle sobre tais Websites e não endossamos ou
                        nos responsabilizamos por qualquer conteúdo. O Usuário
                        expressamente reconhece e concorda que o CONSÓRCIO MATO
                        GROSSO ENERGIA RENOVÁVEL não será responsável, direta ou
                        indiretamente, por quaisquer perdas e danos que sejam
                        efetiva ou alegadamente causados por, ou em conexão,
                        pela confiança depositada em tais Informações, bens e
                        serviços disponíveis no ou através de tais Websites de
                        terceiros.
                        <br />
                        <br />
                        <strong>4.CANAIS DE COMUNICAÇÃO</strong>
                        <br />
                        <br />
                        Com vistas a facilitar o diálogo entre a Mato Grosso
                        Energia Renovável e os usuários, poderão ser
                        disponibilizados canais e funcionalidades de comunicação
                        digital através de suas plataformas oficiais. É de
                        responsabilidade do usuário a utilização desses canais
                        em conformidade com o Termo de Uso em vigor e a
                        legislação brasileira, apenas com o intuito de receber e
                        emitir mensagens e materiais na plataforma digital
                        apropriados e adequados ao fim a que se destina. Sendo
                        assim, fica o usuário estritamente proibido de se
                        utilizar dos meios de comunicação para violação de
                        direitos legalmente protegidos, incluindo, mas não se
                        limitando, os direitos de privacidade, intimidade,
                        autorais, de publicação, dentre outros. O usuário se
                        compromete, ainda, a agir com boa-fé, mantendo uma
                        conduta digna e respeitosa em todas as suas
                        manifestações, abstendo-se de produzir conteúdo
                        difamatório, abusivo, ou discriminatório relacionado a
                        qualquer tema. Também não é permitida a realização de
                        “uploads” de materiais ou informações ilegais,
                        impróprias, indecentes, obscenas, bem como de arquivos
                        corrompidos, com vírus, ou que possam causar danos ao
                        computador de outros usuários. Fica vedada a
                        disseminação de qualquer material ou arquivo protegido
                        pela legislação de propriedade intelectual ou por
                        direitos autorais, exceto nos casos em que o usuário
                        possua todos os consentimentos necessários para tanto.
                        Por fim, é direito do CONSÓRCIO MATO GROSSO ENERGIA
                        RENOVÁVEL realizar a supervisão e gestão dos seus canais
                        de comunicação, podendo, inclusive, revisar e remover
                        materiais postados que considerar impróprios, ilegais,
                        em desacordo com o presente Termo de Uso,ou por qualquer
                        outro motivo relevante, sendo permitida até mesmo a
                        restrição do direito de acesso de qualquer usuário
                        integral ou parcialmente aos serviços de comunicação
                        oficiais.
                        <br />
                        <br />
                        <strong>5.LIMITAÇÃO DE RESPONSABILIDADE</strong>
                        <br />
                        <br />
                        Todo o conteúdo e funcionalidades do website são
                        disponibilizados sem garantias de qualquer espécie,
                        sejam expressas ou implícitas, incluindo, entre outras,
                        garantias de adequação para um objetivo em particular. O
                        CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL não será, em
                        hipótese alguma, responsabilizado perante qualquer
                        pessoa, por qualquer tipo de perda, dano, custo ou
                        despesa resultante de qualquer erro, omissão, ou
                        alteração nas informações aqui fornecidas, nem tampouco
                        por quaisquer atrasos, inexatidões, erros, ou
                        interrupções ocasionados em função destes eventos,
                        durante o suprimento de qualquer informação através das
                        páginas do Website. Ainda, o CONSÓRCIO MATO GROSSO
                        ENERGIA RENOVÁVEL não é nem será responsável pela
                        qualidade da conexão do computador e/ou dispositivo
                        móvel do Usuário à internet em qualquer circunstância;
                        pela disponibilidade ou não dos serviços de acesso à
                        internet prestados por terceiros; pela impossibilidade
                        de utilização de quaisquer informações ou serviços acima
                        mencionados em razão da incompatibilidade da
                        configuração técnica do computador e/ou dispositivo
                        móvel dos Usuários; e pela reparação de danos de
                        qualquer natureza causados pela quebra de privacidade ou
                        segurança de dados dos Usuários durante a conexão de seu
                        dispositivo móvel à internet através de terceiros.
                        <br />
                        <br />
                        <strong>6.DISPOSIÇÕES GERAIS</strong>
                        <br />
                        <br />
                        Na hipótese de qualquer das disposições ou condições
                        destes termos vir a ser considerada inválida, nula ou
                        inexequível, o referido trecho deverá ser interpretado
                        em sintonia com a legislação aplicável ao caso concreto,
                        devendo refletir, na medida do possível, a essência do
                        acordo original e a intenção das partes, sendo
                        substituído por outro que preserve o equilíbrio e a
                        coerência estipulada neste Instrumento. Todo o restante
                        das suas disposições deverá se manter em pleno vigor e
                        validade, não sendo afetada e nem acarretando a
                        invalidade ou impedimento de qualquer forma. Nenhuma
                        tolerância do CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL
                        com relação a exigência de quaisquer direitos ou
                        disposições aqui estabelecidas implicará em renúncia,
                        não podendo ser invocada como justificativa para
                        descumprimento de obrigações, e nem impedirá o exercício
                        regular de seus direitos, dentro dos prazos legais.
                        <br />
                        <br />
                        <strong>7.LEI APLICÁVEL E JURISDIÇÃO</strong>
                        <br />
                        <br />
                        Esses Termos de Uso estão sujeitos a lei brasileira e
                        jurisdição dos tribunais brasileiros. As partes elegem o
                        foro da Comarca de Cuiabá, Mato Grosso, como competente
                        para dirimir quaisquer controvérsias decorrentes deste
                        “Temo de Uso”, independentemente de qualquer outro, por
                        mais privilegiado que seja ou venha a ser.
                        <br />
                        <br />
                        <strong>8.ESSE TERMO</strong>
                        <br />
                        <br />
                        SE APLICA AOS CONSÓRCIOS: O CONSÓRCIO MATO GROSSO
                        ENERGIA RENOVÁVEL, inscrito no CNPJ/ME sob nº
                        36.581.069/0001-01, com sede na Avenida dos Florais,
                        1788, Sala 06, Ribeirão do Lipa, CEP 78.049-520,
                        Cuiabá/MT., (“CONSÓRCIO MATO GROSSO”) O CONSÓRCIO MATO
                        GROSSO II ENERGIA RENOVÁVEL, inscrito no CNPJ/ME sob nº
                        39.430.563/0001-55 com sede na Avenida dos Florais,
                        1788, Sala 07 B, Ribeirão do Lipa, CEP 78.049-520,
                        Cuiabá/MT., (“CONSÓRCIO MATO GROSSO”) O CONSÓRCIO
                        SUSPIRO ENERGIA RENOVÁVEL, inscrito no CNPJ/ME sob nº
                        39.398.885/0001-64, com sede na Avenida dos Florais,
                        1788, Sala 07 C, Ribeirão do Lipa, CEP 78.049-520,
                        Cuiabá/MT., (“CONSÓRCIO MATO GROSSO”) O CONSÓRCIO
                        SUSPIRO II ENERGIA RENOVÁVEL, inscrito no CNPJ/ME sob nº
                        39.374.554/0001-94, com sede na Avenida dos Florais,
                        1788, Sala 07 C, Ribeirão do Lipa, CEP 78.049-520,
                        Cuiabá/MT., (“CONSÓRCIO MATO GROSSO”)
                        <br />
                        <br />
                        <center>
                          <strong>POLITICA E PRIVACIDADE</strong>
                        </center>{" "}
                        <br />
                        <strong>ÍNDICE</strong>
                        <br />
                        1.Declaração de Privacidade de Dados 3
                        <br />
                        2.Definições, Meios de Coleta e Finalidade do Tratamento
                        3
                        <br />
                        2.1.Agentes de Tratamento 4
                        <br />
                        2.2.Finalidade do Tratamento de Dados Pessoais 4
                        <br />
                        2.3.Tipos de Dados Coletados no Website e Formas
                        deRegistro 5
                        <br />
                        2.4.Dados de Navegação de Internet 5
                        <br />
                        3.Armazenamento e Retenção de Dados 5
                        <br />
                        4.Compartilhamento de Dados Pessoais 6
                        <br />
                        5.Transferência Internacional de Dados 6
                        <br />
                        6.Medidas de Segurança 7
                        <br />
                        7.Link de Terceiros 7
                        <br />
                        8.Direitos do Titular 7
                        <br />
                        9.Comunicação: Alteração, Cancelamento ou Dúvidas sobre
                        a Presente Política de Privacidade 9
                        <br />
                        10.Encarregado pela Proteção de Dados 9
                        <br />
                        11.Alterações desta Política de Privacidade 9
                        <br />
                        12.Revisão e Aprovação desta Política 10
                        <br />
                        <br />
                        <strong>
                          {" "}
                          1.DECLARAÇÃO DE PRIVACIDADE DE DADOS O CONSÓRCIO MATO
                          GROSSO ENERGIA RENOVÁVEL
                        </strong>
                        , tem a preocupação de respeitar e resguardar a Sua
                        privacidade. Assim, com o comprometimento e
                        transparência que lhe são característicos, o CONSÓRCIO
                        MATO GROSSO t edita a presente política de privacidade
                        (“Política de Privacidade” ou “Política”) para informar
                        e esclarecer como e com qual finalidade poderá ocorrer o
                        Tratamento dos Seus Dados Pessoais fornecidos ao
                        CONSÓRCIO MATO GROSSO, de acordo com a legislação
                        aplicável no Brasil, em especial com a Lei 13.709/2018
                        (Lei Geral de Proteção de Dados Pessoais). Por favor,
                        leia atentamente esta Política de Privacidade para ter o
                        conhecimento de como e com qual finalidade os Seus Dados
                        Pessoais podem ser coletados pelo CONSÓRCIO MATO GROSSO.
                        É importante que a Política de Privacidade seja
                        interpretada em conjunto e de acordo com qualquer outro
                        documento, contrato ou cláusula de privacidade que a
                        acompanhe. O CONSÓRCIO MATO GROSSO atuará como
                        controlador dos Seus Dados Pessoais, ou seja, cabe a Nós
                        a competência das decisões referentes ao Tratamento dos
                        Dados Pessoais.
                        <br />
                        <br />
                        <strong>
                          2.DEFINIÇÕES, MEIOS DE COLETA E FINALIDADE DO
                          TRATAMENTO
                        </strong>
                        <br />
                        Para melhor compreensão desta Política é necessário
                        entender as seguintes definições, que podem ser lidas no
                        plural ou singular: •Autoridade Nacional de Proteção de
                        Dados (ANPD): órgão da administração pública responsável
                        por zelar, implementar e fiscalizar o cumprimento desta
                        Lei em todo o território nacional. •Controlador(a):
                        pessoa natural ou jurídica, de direito público ou
                        privado, a quem competem as decisões referentes ao
                        Tratamento de Dados Pessoais; •Dado Pessoal: qualquer
                        informação relacionada a pessoa natural identificada ou
                        identificável. •Dado Pessoal Sensível: dado pessoal
                        sobre origem racial ou étnica, convicção religiosa,
                        opinião política, filiação a sindicato ou a organização
                        de caráter religioso, filosófico ou político, dado
                        referente à saúde ou à vida sexual, dado genético ou
                        biométrico, quando vinculado a uma pessoa natural.
                        •Encarregado: pessoa indicada pelo controlador e
                        operador para atuar como canal de comunicação entre o
                        controlador, os Titulares e a Autoridade Nacional de
                        Proteção de Dados. •Lei Geral de Proteção de Dados
                        (LGPD): Lei nº 13.709/2018 que dispõe sobre a proteção
                        de Dados Pessoais. •Operador: pessoa natural ou
                        jurídica, de direito público ou privado, que realiza o
                        Tratamento de Dados Pessoais em nome do Controlador.
                        •Site: https://matogrossoenergia.com.br e demais
                        plataformas nele hospedadas, bem como por outros meios
                        de comunicação eventualmente disponibilizados pelo
                        CONSÓRCIO MATO GROSSO. •Titular: pessoa natural a quem
                        se referem os Dados Pessoais que são objeto de
                        Tratamento. •Tratamento: toda operação realizada com
                        Dados Pessoais, como as que se referem a coleta,
                        produção, recepção, classificação, utilização, acesso,
                        reprodução, transmissão, distribuição, processamento,
                        arquivamento, armazenamento, eliminação, avaliação ou
                        controle da informação, modificação, comunicação,
                        transferência, difusão ou extração.
                        <br />
                        <br />
                        <strong>2.1.AGENTES DE TRATAMENTO</strong> <br /> Nós
                        assumimos a posição de Controladora de Seus Dados
                        Pessoais quando a Nós competem as decisões referentes ao
                        Tratamento de Seus Dados Pessoais, sempre observando a
                        LGPD, as demais normas aplicáveis e essa Política.
                        <br />
                        <br />
                        <strong>
                          2.2.FINALIDADE DO TRATAMENTO DE DADOS PESSOAIS
                        </strong>
                        <br />
                        O Tratamento dos Seus Dados Pessoais pode ser realizado
                        pelo CONSÓRCIO MATO GROSSO mediante o Seu consentimento,
                        quando aplicável, em diversos meios, por obrigação
                        legal, regulatória, contratual ou outras formas. O
                        CONSÓRCIO MATO GROSSO pode solicitar que Você forneça o
                        consentimento escrito ou por qualquer meio que confirme
                        tal consentimento, sempre que necessário. O Tratamento
                        dos Seus Dados Pessoais são coletados com a finalidade
                        de viabilizar e/ou melhorar o acesso e utilização da
                        Geração Distribuída dos Consorciados ou daqueles que
                        possuam o interesse de ingresso, bem como: a)Para
                        identificar e/ou oferecer-lhe conteúdo relevante sobre
                        determinada preferência e/ou interesse manifestado por
                        Você ao CONSÓRCIO MATO GROSSO, incluindo, sem limitação,
                        newsletters, eventos, convites, lembretes, notas de
                        agradecimento, entre outros; b)Para formação de banco de
                        dados de possíveis ingressantes ao Consórcio; c)Para
                        proteção, defesa e administração dos interesses do
                        CONSÓRCIO MATO GROSSO; d)Para o cumprimento da
                        legislação aplicável;Para notificar-lhe acerca de
                        alteração da presente Política de Privacidade; e)Para o
                        cumprimento de qualquer outra demanda por Você
                        solicitada ao CONSÓRCIO MATO GROSSO.
                        <br />
                        <br />
                        <strong>
                          2.3.TIPOS DE DADOS COLETADOS NO WEBSITE E FORMAS DE
                          REGISTRO O CONSÓRCIO MATO GROSSO
                        </strong>{" "}
                        Coleta Dados Pessoais através de formulários online ou
                        por meio físico quando Você, por exemplo, registra-se
                        para um evento, envia informações para ingresso no
                        consórcio, realização de análise de seu consumo de
                        energia elétrica ou quando preenche um formulário de
                        “contato” via Website. Quando Você registra ou envia as
                        Suas informações para o CONSÓRCIO MATO GROSSO, nós
                        frequentemente solicitamos informações sobre o Seu nome,
                        e-mail, telefone, e dados de consumo de energia
                        elétrica.
                        <br />
                        <br />
                        <strong>2.4.DADOS DE NAVEGAÇÃO DE INTERNET</strong>{" "}
                        <br />
                        Quando Você visita nosso website, Nós coletamos dados de
                        registro de internet padrão e os detalhes de padrões de
                        comportamento do visitante. O CONSÓRCIO MATO GROSSO
                        executa esta ação para levantar informações, tais como o
                        número de visitantes às diversas partes do website do
                        CONSÓRCIO MATO GROSSO. As informações são coletadas de
                        forma a não identificar um indivíduo. Além disso, não
                        fazemos nenhuma tentativa de descobrir as identidades
                        daqueles que visitam nosso site. Nós usamos ferramentas
                        de analytics para ajudar a analisar o acesso e uso do
                        nosso website. A ferramenta usa “cookies”, que são
                        arquivos de texto localizados em Seu computador, para
                        coletar informações de registro de internet padrão e
                        informações de comportamento de visitantes de forma
                        anônima, sempre com o objetivo de avaliar o uso dos
                        visitantes no website e para compilar relatórios
                        estatísticos sobre a atividade no website do CONSÓRCIO
                        MATO GROSSO. Você poderá desabilitar ou remover os
                        cookies por ferramentas que estão disponíveis na maioria
                        dos navegadores comerciais. As preferências de cada
                        navegador que Você usa precisarão ser selecionadas
                        separadamente e navegadores diferentes oferecem funções
                        e opções diferentes. Caso tenha interesse em saber mais
                        sobre cookies, incluindo como controla-los, visite o
                        site: https://www.allaboutcookies.org/
                        <br />
                        <br />
                        <strong>2.5.ARMAZENAMENTO</strong>
                        <br />
                        E RETENÇÃO DE DADOS O CONSÓRCIO MATO GROSSO poderá
                        armazenar os Seus Dados Pessoais pelo tempo necessário
                        ao cumprimento das finalidades citadas nesta Política,
                        assim como para o cumprimento de obrigação legal ou
                        regulatória; o exercício regular de direitos em processo
                        judicial, administrativo ou arbitral; ou para outras
                        finalidades previstas na LGPD, como, por exemplo, para
                        seu uso exclusivo, de forma anonimizada ou em situações
                        que justifiquem o seu legítimo interesse. Para a
                        determinação da forma e duração do Tratamento dos Seus
                        Dados Pessoais pelo CONSÓRCIO MATO GROSSO, será
                        considerada a natureza dos Seus Dados Pessoais
                        fornecidos ao CONSÓRCIO MATO GROSSO, assim como a
                        finalidade do Tratamento. Durante todo o período em que
                        mantivermos os Seus Dados Pessoais armazenados, todas as
                        medidas razoáveis serão tomadas para mantê-los
                        protegidos, em ambientes seguros e controlados,
                        respeitando a legislação vigente. Por essa razão, Seus
                        Dados Pessoais serão armazenados com o emprego de altos
                        níveis de segurança, contando inclusive com práticas de
                        controle de acesso. Para mais informações sobre onde e
                        quanto tempo Seus Dados Pessoais serão armazenados, bem
                        como sobre mais informações a respeito dos Seus direitos
                        de exclusão e portabilidade, contate
                        contato@matogrossoenergia.com.br
                        <br />
                        <br />
                        <strong>3.COMPARTILHAMENTO DE DADOS PESSOAIS</strong>
                        <br />O CONSÓRCIO MATO GROSSO não comercializará os Seus
                        Dados Pessoais. Entretanto, Seus Dados Pessoais podem
                        ser compartilhados ou transferidos a terceiros, no
                        Brasil ou no exterior, com o objetivo de cumprir
                        adequadamente as finalidades enumeradas nesta política,
                        assim como para o cumprimento de ordem judicial ou
                        decisão de qualquer outra autoridade competente para
                        tanto, segundo a legislação aplicável. Sendo assim, o
                        CONSÓRCIO MATO GROSSO pode compartilhar ou transferir os
                        Seus Dados Pessoais com terceiros, dentro ou fora do
                        Brasil, nas seguintes hipóteses: (i)Prestadores de
                        serviços contratados para atuar em nome do CONSÓRCIO
                        MATO GROSSO para fornecer um serviço seguindo as Nossas
                        instruções, para ajudar a melhorar ou facilitar Nossos
                        serviços, como por exemplo, provedores de sistemas de
                        TI, provedores de serviços em nuvem, serviços
                        relacionados a folha de pagamentos e recursos humanos,
                        dentre outros; (ii)Parceiros comerciais com as quais o
                        CONSÓRCIO MATO GROSSO mantém relacionamento de
                        colaboração ou aliança; e (iii)Autoridades
                        administrativas e judiciais que, no exercício de sua
                        competência, exigem informações. Se o CONSÓRCIO MATO
                        GROSSO necessitar compartilhar Seus Dados Pessoais com
                        terceiros que não sejam os mencionados atualizaremos
                        essa Política. Em todas as hipóteses, o CONSÓRCIO MATO
                        GROSSO se compromete a compartilhar estritamente os
                        Dados Pessoais que se fizerem necessários ao cumprimento
                        da respectiva finalidade ou atendimento da respectiva
                        ordem específica, conforme o caso.
                        <br />
                        <br />
                        <strong>4.TRANSFERÊNCIA</strong>
                        <br />
                        INTERNACIONAL DE DADOS Os serviços fornecidos pelo
                        CONSÓRCIO MATO GROSSO requerem o suporte de uma
                        infraestrutura tecnológica que pode ser estabelecida
                        fora do Brasil, como servidores e serviços em nuvem
                        (cloud), que podem ser de propriedade ou fornecidos por
                        terceiros. Além disso para o exercício de suas
                        atividades o CONSÓRCIO MATO GROSSO pode ter que
                        compartilhar Seus Dados Pessoais com terceiros fora do
                        Brasil, assim como com Seu escritório localizado em Nova
                        Iorque, Estados Unidos da América. Nessas hipóteses o
                        CONSÓRCIO MATO GROSSO assegura somente contratar
                        terceiros que atendam os mais altos padrões de
                        segurança, pelo menos, no mesmo nível de proteção de
                        Dados Pessoais adequado à legislação brasileira.Medidas
                        de Segurança O CONSÓRCIO MATO GROSSO e os terceiros com
                        os quais os Seus Dados Pessoais venham a ser
                        eventualmente compartilhados observam os padrões de
                        segurança necessários à prevenção e remediação do acesso
                        desautorizado de Dados Pessoais, empregando os meios
                        aplicáveis e padrões de segurança recomendados para
                        protegê-los, na medida em que forem técnica e
                        operacionalmente viáveis. O CONSÓRCIO MATO GROSSO
                        valoriza a Sua confiança em nos fornecer Seus Dados
                        Pessoais e, portanto, adota medidas de segurança,
                        técnicas e administrativas aptas a proteger os Seus
                        Dados Pessoais de acessos não autorizados e de situações
                        acidentais ou ilícitas de destruição, perda, alteração,
                        comunicação ou qualquer forma de Tratamento inadequado
                        ou ilícito. Buscamos sempre implementar procedimentos
                        físicos, eletrônicos e gerenciais para monitorar a
                        segurança de Seus Dados Pessoais e qualquer outra
                        informação que coletamos. Apesar de adotarmos as
                        melhores práticas de segurança existentes no mercado e
                        realizarmos esforços para proteger os Seus Dados
                        Pessoais, deve-se considerar que nenhum Tratamento de
                        Dados Pessoais é completamente seguro. O CONSÓRCIO MATO
                        GROSSO não se responsabilizará, em nenhuma hipótese, nem
                        perante Você nem perante terceiros, por prejuízos de
                        qualquer espécie, inclusive, mas sem limitação, aqueles
                        decorrentes de divulgação dos Seus Dados Pessoais a
                        terceiros ou extravio de tais informações decorrentes
                        direta ou indiretamente do mau uso da Internet pelo
                        Usuário. O CONSÓRCIO MATO GROSSO está isento de qualquer
                        responsabilidade por danos resultantes do uso indevido
                        do Site, por Você e/ou quaisquer terceiros por ele
                        autorizados, ou que resultem de acidente, modificação ou
                        quaisquer outras condutas e/ou condições não controladas
                        pelo CONSÓRCIO MATO GROSSO.
                        <br />
                        <br />
                        <strong>5.LINK DE TERCEIROS</strong>
                        <br /> O CONSÓRCIO MATO GROSSO pode oferecer links para
                        redirecionamento a websites de terceiros, com a
                        finalidade de melhorar a sua experiência de navegação,
                        informação ou prestação de serviços. O CONSÓRCIO MATO
                        GROSSO esclarece que a presente Política de Privacidade
                        não se aplica a Dados Pessoais por Você fornecidos a
                        quaisquer companhias, indivíduos e/ou organizações que
                        não o CONSÓRCIO MATO GROSSO. Tais pessoas, físicas ou
                        jurídicas, podem adotar diferentes políticas
                        relacionadas à privacidade e informação com relação aos
                        Dados Pessoais, por elas coletados e de qualquer outro
                        modo tratados. O CONSÓRCIO MATO GROSSO sugere a consulta
                        à política de privacidade aplicável a essas pessoas e/ou
                        websites de terceiros antes do fornecimento de Seus
                        Dados Pessoais.
                        <br />
                        <br />
                        <strong>6.DIREITOS DO TITULAR</strong>
                        <br /> O CONSÓRCIO MATO GROSSO respeita a Sua
                        privacidade e preocupa-se em disponibilizar os canais
                        necessários para que Você possa exercer os Seus direitos
                        e ter informações adequadas, claras e transparentes
                        sobre o uso e o Tratamento dos Seus Dados Pessoais.
                        Desse modo, a solicitação de alteração de Dados Pessoais
                        incompletos, inexatos ou desatualizados e/ou de exclusão
                        de dados fornecidos ao CONSÓRCIO MATO GROSSO, inclusive
                        Dados Pessoais, deverá ser realizada por e-mail
                        direcionado ao contato@matogrossoenergia.com.br Tal
                        solicitação será analisada, e, caso não implique
                        interrupção de fornecimento dos serviços prestados pelo
                        CONSÓRCIO MATO GROSSO ou incida em alguma das hipóteses
                        de conservação dos dados, será procedida. Caso implique
                        interrupção de fornecimento dos serviços, a Sua relação
                        com o CONSÓRCIO MATO GROSSO estará encerrada, mas as
                        obrigações decorrentes da prestação permanecerá válida
                        e, neste caso, as Suas informações e Dados Pessoais
                        continuarão sendo usados e tratados pelo CONSÓRCIO MATO
                        GROSSO e/ou terceiros autorizados até que cesse a
                        necessidade ou as finalidades previstas nessa Política.
                        Além da alteração e exclusão dos Dados Pessoais, Você
                        também poderá exercer os seguintes direitos, mediante
                        requerimento ao CONSÓRCIO MATO GROSSO, através de e-mail
                        direcionado ao contato@matogrossoenergia.com.br:
                        <br />
                        <br />
                        <strong>Direito de confirmação</strong>
                        <br />
                        <br />
                        Você pode solicitar a confirmação da existência ou não
                        de atividades de Tratamento de Dados Pessoais
                        relacionados a Você. As seguintes informações sobre as
                        atividades de Tratamento de Dados Pessoais podem ser
                        solicitadas:
                        <br />
                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;a) </strong>quais Dados
                        Pessoais estão sendo tratados;
                        <br />
                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;b)</strong>a origem dos
                        Dados Pessoais tratados;
                        <br />
                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;c)</strong>a finalidade
                        das atividades de Tratamento de Dados Pessoais;
                        <br />
                        <br />
                        <strong>Direito de acesso</strong> <br />
                        Você tem o direito de nos solicitar o acesso aos Seus
                        Dados Pessoais tratados por Nós. Pode ser solicitada uma
                        declaração completa acerca das atividades de Tratamento
                        e informações adicionais sobre as atividades realizadas.
                        <br />
                        <br />
                        <strong>Direito de retificação</strong>
                        <br />
                        Você tem o direito de solicitar a correção, alteração ou
                        atualização de Seus Dados Pessoais quando estiverem
                        incorretos ou incompletos.
                        <br />
                        <br />
                        <strong>Direito de oposição</strong>
                        <br /> Você tem o direito de se opor aos Tratamentos de
                        Dados Pessoais realizado, em caso de descumprimento ao
                        disposto na LGPD.
                        <br />
                        <br />
                        <strong>Direito à portabilidade</strong>
                        <br /> Você pode solicitar a portabilidade de Seus Dados
                        Pessoais para terceiros. Isto significa que Você pode
                        solicitar uma cópia dos Seus Dados Pessoais em formato
                        eletrônico e transmiti-los para utilização em serviço de
                        terceiros. Caso Você nos solicite e seja tecnicamente
                        possível, transferiremos para Você as Suas informações
                        pessoais. No entanto, apenas realizaremos a
                        portabilidade em relação aos Seus Dados Pessoais e não
                        de terceiros.
                        <br />
                        <br />
                        <strong>Direito de eliminação</strong>
                        <br />
                        Você tem o direito de nos solicitar a remoção de Dados
                        Pessoais que armazenamos sobre Você, se não for mais
                        necessário ou se forem excessivos para as finalidades
                        para as quais foram inicialmente coletados.
                        <br />
                        <br />
                        <strong>Direito de peticionar</strong>
                        <br /> Você tem o direito de apresentar uma reclamação
                        junto à Autoridade Nacional de Proteção de Dados
                        Pessoais. No entanto, recomendamos fortemente que Você
                        entre em contato conosco primeiro para que busquemos a
                        melhor solução, levando em consideração sempre a Sua
                        privacidade e a proteção de Seus Dados Pessoais.
                        <br />
                        <br />
                        <strong>
                          Direito de solicitar a revisão de decisões unicamente
                          automatizadas
                        </strong>
                        <br /> Você tem o direito de não se submeter às decisões
                        tomadas de forma unicamente automatizada que afetem Seus
                        interesses, incluídas as decisões destinadas a definir o
                        Seu perfil pessoal, profissional, de consumo e de
                        crédito ou os aspectos de Sua personalidade. Nesses
                        casos, Você pode solicitar que essas decisões sejam
                        revisadas.
                        <br />
                        <br />
                        <strong>
                          7.COMUNICAÇÃO: ALTERAÇÃO, CANCELAMENTO OU DÚVIDAS
                          SOBRE A PRESENTE POLÍTICA DE PRIVACIDADE
                        </strong>
                        <br /> Se Você deseja acessar, alterar ou excluir Seus
                        Dados Pessoais fornecidos ao CONSÓRCIO MATO GROSSO, ou
                        exercer quaisquer um dos Seus Direitos como Titular, por
                        favor entre em contato pelo e-mail
                        ontato@matogrossoenergia.com.br e iremos tomar as
                        medidas necessárias e/ou respondê-lo em prazo razoável,
                        segundo a viabilidade técnica e operacional do CONSÓRCIO
                        MATO GROSSO. O CONSÓRCIO MATO GROSSO também poderá pedir
                        para que Você atualize os Seus Dados Pessoais
                        periodicamente. Se Você não estiver de acordo com a
                        presente Política de Privacidade, deseja excluir
                        eventuais Dados Pessoais tratados pelo CONSÓRCIO MATO
                        GROSSO ou obter maiores esclarecimentos sobre a
                        aplicação dessa Política de Privacidade assim como sobre
                        os Seus direitos, por favor entre em contato conosco no
                        e-mail Teremos a satisfação de esclarecer-lhe eventuais
                        dúvidas e/ou atender à Sua solicitação. Por fim, se Você
                        recebeu uma comunicação do CONSÓRCIO MATO GROSSO e
                        preferia não tê-la recebido, por favor deixe- nos saber
                        por meio do link “Cancelar Inscrição” presente no
                        respectivo e-mail, ou envie-nos um e-mail para
                        contato@matogrossoenergia.com.br O objetivo do CONSÓRCIO
                        MATO GROSSO será responder a todas as demandas acima com
                        a maior brevidade possível.
                        <br />
                        <br />
                        <strong>8.ENCARREGADO PELA PROTEÇÃO DE DADOS</strong>
                        <br /> O CONSÓRCIO MATO GROSSO possui sede em Cuiabá/MN.
                        O contato do Encarregado pela Proteção de Dados do
                        CONSÓRCIO MATO GROSSO é: Avenida xxxxxxxxxxxxxxxxx, Fone
                        65 8888888888 contato@matogrossoenergia.com.br
                        <br />
                        <br />
                        <strong>
                          9.ALTERAÇÕES DESTA POLÍTICA DE PRIVACIDADE
                        </strong>
                        <br /> Todos os Dados Pessoais tratados pelo CONSÓRCIO
                        MATO GROSSO estarão de acordo com esta Política de
                        Privacidade e com as finalidades supracitadas. O
                        CONSÓRCIO MATO GROSSO se reserva no direito de alterar
                        total ou parcialmente a presente Política de Privacidade
                        a qualquer tempo, inserindo a última data de atualização
                        conforme indicado abaixo. Por favor, consulte a presente
                        Política de Privacidade periodicamente para verificar
                        eventuais alterações. O uso do website do CONSÓRCIO MATO
                        GROSSO ou o fornecimento de Dados Pessoais por qualquer
                        outro meio pressupõe a Sua concordância com a presente
                        Política de Privacidade.
                        <br />
                        <br />
                        <strong>10.REVISÃO E APROVAÇÃO DESTA POLÍTICA</strong>
                        <br /> Esta política deve ser revisada a cada 2 anos ou
                        a qualquer momento se assim desejado pelo CONSÓRCIO MATO
                        GROSSO ou necessário, obedecendo ao ciclo de aprovações
                        das áreas e alçadas envolvidas. A versão atualizada
                        desta política será devidamente disponibilizada nesta
                        página quando ocorrer. <br />
                        <br />
                        <strong>
                          11.11 . ESSA POLÍTICA SE APLICA AOS CONSÓRCIOS:
                        </strong>
                        <br />
                        <br />O{" "}
                        <strong>CONSÓRCIO MATO GROSSO ENERGIA RENOVÁVEL</strong>
                        , inscrito no CNPJ/ME sob nº 36.581.069/0001-01, com
                        sede na Avenida dos Florais, 1788, Sala 06, Ribeirão do
                        Lipa, CEP 78.049-520, Cuiabá/MT., (“CONSÓRCIO MATO
                        GROSSO”) O
                        <strong>
                          CONSÓRCIO MATO GROSSO II ENERGIA RENOVÁVEL
                        </strong>
                        , inscrito no CNPJ/ME sob nº 39.430.563/0001-55 com sede
                        na Avenida dos Florais, 1788, Sala 07 B, Ribeirão do
                        Lipa, CEP 78.049-520, Cuiabá/MT., (“CONSÓRCIO MATO
                        GROSSO”) O
                        <strong>CONSÓRCIO SUSPIRO ENERGIA RENOVÁVEL</strong>,
                        inscrito no CNPJ/ME sob nº 39.398.885/0001-64, com sede
                        na Avenida dos Florais, 1788, Sala 07 C, Ribeirão do
                        Lipa, CEP 78.049-520, Cuiabá/MT., (“CONSÓRCIO MATO
                        GROSSO”) O CONSÓRCIO SUSPIRO II ENERGIA RENOVÁVEL,
                        inscrito no CNPJ/ME sob nº 39.374.554/0001-94, com sede
                        na Avenida dos Florais, 1788, Sala 07 C, Ribeirão do
                        Lipa, CEP 78.049-520, Cuiabá/MT., (“CONSÓRCIO MATO
                        GROSSO”) <br />
                        <br />
                        <strong>
                          Data de publicação desta Política de Privacidade no
                          website: 18 de março de 2022.
                        </strong>
                      </p>
                    </div>
                    <Form>
                      <div className="mt-3 d-flex align-items-center justify-content-between">
                        <div className="ms-3">
                          <label>
                            <Field
                              type="checkbox"
                              name="termoAdesao"
                              Required
                            />
                            &nbsp;Aceito os termos de uso e politicas de
                            privacidade
                          </label>
                          {/* <input type="checkbox" id="termo" name="termo" /> */}

                          {/* <button onClick={() => setChecked((c) => !c)}>
                              &nbsp; Aceite o termo de adesão
                            </button> */}
                        </div>
                        <div className="pb-2 me-3">
                          <button
                            // type="button"
                            className="btn cadastro_btn"
                            onClick={() => handleSubmit()}
                          >
                            Continuar
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                  {/* <div class="card cadastro_economia col-xl-4 col-11 col-sm-12">
                    <div class="card-body">
                      <h3 class="cadastro_titulo">Economia Atual</h3>
                      <p class="mb-1">
                        Contrato de <strong className="text-bold">12 meses</strong> economizar
                        projetada de
                      </p>
                      <h3 className="paragraph-valores nowrap">
                        {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">
                        Benefício
                      </p>
                      <h3 className="paragraph-valores nowrap">19%</h3>
                      <p className="titulo-valores mt-4 mb-1">
                      Valor atual da energia
                      </p>
                      <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                      {'R$ '+numeral(valorSemDesconto).format('0,0.00')}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Valor do plano</p>
                      <h3 className="paragraph-valores nowrap mb-2">{'R$ '+numeral(valor1Mes).format('0,0.00')}</h3>
                    </div>
                  </div> */}
                  {/* <EconomiaTotal/> */}
                </div>
              </section>
              <footer>
                <div className="background-gray-darker">
                  <img
                    src="/images/logo-white.svg"
                    className="cadastro_footer_imagem"
                    alt="Logo Energia Fácil"
                    style={{ width: "400px" }}
                  />
                </div>
                <div className="background-blue cadastro_footer_texto">
                  <p className="paragraph-3">
                    Criado e desenvolvido por Energia Fácil
                  </p>
                </div>
              </footer>
            </main>
          </>
        )}
      </Formik>
    </>
  );
}
