//Importação React
import React from "react";

export default function Header() {
  return (
    <>
      <header className="header p-2">
        <center>
          <img
            src="/images/energiaFacil.svg"
            alt="Logo Energia Fácil"
            style={{width: "280px"}}
          />
        </center>
      </header>
    </>
  );
}
