import axios from "axios";
import { config } from "../../../Config";
export const baseURL = config.url.apiEndPoint;

const api = axios.create();

export async function obterDocumento(documento) {
  const response = await api.post(`${baseURL}/obter/informacoes/cocacola`, {
    CpfCnpj: documento,
  });

  return response.data.entities;
};