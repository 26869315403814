import React from "react";

export default function Landing() {
  return (
    <>
      <section className="container ">
        <div className="row d-flex justify-content-center align-items-center ">
          <div className="esquerda-landing col-xl-6 col-sm-12 col-md-12 col-lg-6 col-12">
            <h1 className="title-4 mb-2">
              Fazer a escolha certa nem sempre é difícil.
            </h1>
            <p>
              Você deixa de emitir gás carbônico (CO2) todos os anos,
              contribuindo para a redução do efeito estuda e ajuda a preservar a
              natureza
            </p>
            <div className="mt-3 landing-button d-flex justify-content-center align-items-center">
              <a href="#plano">Faça uma simulação</a>
            </div>
          </div>
          <div className="direita-landing col-xl-5 col-sm-12 col-md-8 col-lg-6 col-12">
            <img src="/images/lampada.svg" alt="Lampada" />
          </div>
        </div>
      </section>
    </>
  );
}
