import React, { useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
const Question = ({ title, info }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <article className="d-flex align-items-center question">
      
      <div>
        <header>
          <div>
          <p className="pergunta" onClick={() => setExpanded(!expanded)}>
            {title}
          </p>
          </div>
        </header>
        {expanded && <p className="info-perguntas" onClick={() => setExpanded(!expanded)}>{info}</p>}
      </div>
    </article>
  )
}

export default Question
