import * as requestFromServer from "./CocaColaCRUD";
import { cocaColaSlice, callTypes } from "./CocaColaSlice";

const { actions } = cocaColaSlice;

export const obterDocumento = (documento) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .obterDocumento(documento)
    .then((response) => {
      const dadosDocumento = response.data.entities;
      if (response.data.entities) {
        localStorage.setItem('@SolarCocaCola:Dados', JSON.stringify(dadosDocumento));
      };
      dispatch(actions.documentoFetched({ dadosDocumento }));
    })
    .catch(error => {
      error.clientMessage = "Documento não encontrado";
      console.log("Documento não encontrado");
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};