//Importação React
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../cadastro.css";
import { Voltar, Finalizar, EnviarInscricao } from "../../../../../store";
import styles from "./adesao_formulario_documentos.module.css";
import {
  alterarSimulacaoLead,
  uploadArquivoTeste,
} from "../../../../../pages/api/simulacaoLead";
import { Input } from "../../../../../components/control/Input";
import EconomiaTotal from "../components/EconomiaTotal";
import numeral from "../../../../../components/control/numeralBRL";
import { config } from "../../../../../Config";
import axios from "axios";
export const baseURL = config.url.urlUpload;
const api = axios.create();

const AdesaoFormDocSchema = Yup.object().shape({
  // faturaEnergia: Yup.string().required('Fatura é obrigatório'),
  // strParametro5: Yup.string().required('Login é obrigatório'),
  // strParametro6: Yup.string().required('Senha é obrigatório'),
});

export default function Cadastro2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fluxoConsorcio = useSelector((state) => state);
  const idLead = fluxoConsorcio.consorciosCocaCola.idLead;
  const [arquivoCartaoCNPJ, setArquivoCartaoCNPJ] = useState(null);
  const [arquivoFaturaEnergia, setArquivoFaturaEnergia] = useState(null);
  const [labelArquivoFaturaEnergia, setLabelArquivoFaturaEnergia] = useState(
    "Selecione o arquivo da Fatura de Energia"
  );
  const [mudarArquivo, setMudarArquivo] = useState(false);
  const [controlarMensagem, setControlarMensagem] = useState(false);
  const [Extensao, setExtensao] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState(false);

  const fileType = ["application/pdf"];

  const extensoesPermitidas = [".pdf"];

  // onchange event
  // const fileType = ['application/pdf'];
  const uploadOnChangeArquivo = (e, tipoArquivo) => {
    let selectedFile = e.target.files[0];
    if (selectedFile && fileType.includes(selectedFile.type)) {
      setControlarMensagem(false);
      setExtensao(selectedFile.name.substring(selectedFile.name.indexOf(".")));
      let reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = (e) => {
        setArquivoFaturaEnergia(e.target.result);
        setLabelArquivoFaturaEnergia(selectedFile.name);
      };
    } else {
      setControlarMensagem(true);
    }
  };
  const uploadArquivo = (arquivo, tipoArquivo) => {
    return new Promise(async (resolve) => {
      var axios = require("axios");
      if (arquivo == null || arquivo == "") {
        resolve(null);
      }
      var data = { arquivo: arquivo };

      var _config = {
        method: "post",
        url: baseURL + `/uploadFileAnexo`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      api(_config)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          resolve(null);
        });
    });
  };

  const [valor1Mes, setValor1Mes] = useState(0);
  const [valor12Mes, setValor12Mes] = useState(0);
  const [valorSemDesconto, setValorSemDesconto] = useState(0);
  const [idLeadLocal, setIdLead] = useState(0);

  useEffect(() => {
    var valormes1 = localStorage.getItem("valor1Mes");
    var valormes12 = localStorage.getItem("valor12Mes");
    var valorSemDesconto = localStorage.getItem("valorDesconto");
    var idLead = localStorage.getItem("idLeadTela");

    var formatado1 = parseFloat(valormes1);
    var formatado2 = parseFloat(valormes12);
    var formatado3 = parseFloat(valorSemDesconto);
    var formatado4 = parseInt(idLead);

    const wizard = localStorage.getItem("wizard");

    if (wizard !== "true") {
      window.location.href = "http://localhost:3000/";
    }

    setValor1Mes(formatado1);
    setValor12Mes(formatado2);
    setValorSemDesconto(formatado3);
    setIdLead(formatado4);
  }, []);

  const voltar = () => {
    navigate("/adesao/dados-consorcio");
  };

  return (
    <>
      <Formik
        initialValues={{
          idLead: "",
          strParametro5: "",
          strParametro6: "",
          faturaEnergia: "",
        }}
        validationSchema={AdesaoFormDocSchema}
        onSubmit={(values) => {
          if (arquivoFaturaEnergia == null) {
            alterarSimulacaoLead(
              idLeadLocal,
              values.strParametro5,
              values.strParametro6,
              null,
              null,
              null
            ).then(function (response) {
              navigate("/adesao/termos");
              dispatch(
                EnviarInscricao({
                  idLead: idLeadLocal,
                })
              );
            });
          } else {
            uploadArquivo(arquivoFaturaEnergia).then(function (retornoUpload2) {
              let faturaPath;
              let faturaURL;
              if (
                retornoUpload2 != null &&
                retornoUpload2.data != null &&
                retornoUpload2.data.entities != null
              ) {
                faturaPath = retornoUpload2.data.entities.path;
                faturaURL = retornoUpload2.data.entities.url;
              }
              var nomeArquivo = labelArquivoFaturaEnergia;
              if (extensoesPermitidas.includes(Extensao)) {
                alterarSimulacaoLead(
                  idLeadLocal,
                  values.strParametro5,
                  values.strParametro6,
                  faturaURL,
                  faturaPath,
                  nomeArquivo
                ).then(function (response) {
                  navigate("/adesao/termos");
                  dispatch(
                    EnviarInscricao({
                      idLead: idLeadLocal,
                    })
                  );
                });
              }
            });
          }
        }}
      >
        {({ handleSubmit, errors, setFieldValue }) => (
          <>
            <header
              className="header p-3 d-flex align-items-center"
              id="cadastro2"
            >
              <div className="ms-3 me-4">
                <a href="/">
                  <img
                    src="/images/energiaFacil.svg"
                    alt="Logo Energia Fácil"
                    style={{ width: "250px" }}
                  />
                </a>
              </div>
              <div class="progress_2">
                <div class="progress_bar_2"></div>
              </div>
            </header>
            <main class="cadastro_background">
              <section class="container cadastro">
                <div class="row">
                  <div class="card cadastro_fatura col-xl-7 col-11 col-sm-12">
                    <div class="card-body">
                      <h3 class="cadastro_titulo mt-4">
                        Preencha os dados da fatura
                      </h3>
                      <Form>
                        <div class="selecionar-fatura d-flex align-items-center justify-content-between">
                          <div className="mb-2">
                            <h3 className="cadastro_label">Última fatura</h3>
                            <br />
                            <h3 className="cadastro-2_label">
                              {labelArquivoFaturaEnergia}
                            </h3>
                            <br />
                            {controlarMensagem === false ? (
                              <></>
                            ) : (
                              <span
                                style={{
                                  color: "#FA5C7D",
                                  fontSize: "12px",
                                }}
                              >
                                O arquivo deve ser: PDF
                              </span>
                            )}
                            {/* {mensagemAlerta === false ? (
                              <></>
                            ) : (
                              <span
                                style={{
                                  color: '#FA5C7D',
                                  fontSize: '12px',
                                }}>
                                Selecione uma fatura
                              </span>
                            )} */}
                          </div>
                          <div>
                            <label
                              htmlFor="faturaEnergia"
                              style={{
                                paddingRight: "55px",
                                paddingLeft: "55px",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                              }}
                              className="cadastro_btn"
                              name="faturaEnergia"
                              for="faturaEnergia"
                            >
                              {" "}
                              Buscar
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="faturaEnergia"
                              onChange={(e) => uploadOnChangeArquivo(e)}
                              style={{ marginTop: "0.5em" }}
                              accept=".pdf"
                            />
                          </div>
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="strParametro5"
                            className="cadastro_label"
                          >
                            Login do Portal da Distribuidora
                          </label>
                          <Field
                            component={Input}
                            name="strParametro5"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="strParametro6"
                            className="cadastro_label"
                          >
                            Senha do Portal da Distribuidora
                          </label>
                          <Field
                            component={Input}
                            type="password"
                            name="strParametro6"
                            className="form-control"
                          />
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="cadastro__voltar_btn"
                            onClick={voltar}
                          >
                            Voltar
                          </button>
                          <button
                            // type="button"
                            className="cadastro_btn d-flex justify-content-center align-items-center"
                            onClick={handleSubmit}
                            href="#cadastro3"
                          >
                            Continuar
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div class="card cadastro_economia col-xl-4 col-11 col-sm-12">
                    <div class="card-body">
                      <h3 class="cadastro_titulo">Economia Atual</h3>
                      <p class="mb-1">
                        Contrato de{" "}
                        <strong className="text-bold">12 meses</strong> economia
                        projetada de
                      </p>
                      <h3 className="paragraph-valores nowrap">
                        {"R$ " + numeral(valor12Mes).format("0,0.00")}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Benefício</p>
                      <h3 className="paragraph-valores nowrap">19%</h3>
                      <p className="titulo-valores mt-4 mb-1">
                        Valor atual da energia
                      </p>
                      <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                        {"R$ " + numeral(valorSemDesconto).format("0,0.00")}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Valor do plano</p>
                      <h3 className="paragraph-valores nowrap mb-2">
                        {"R$ " + numeral(valor1Mes).format("0,0.00")}
                      </h3>
                    </div>
                  </div>
                </div>
              </section>
              <footer>
                <div className="background-gray-darker">
                  <img
                    src="/images/logo-white.svg"
                    className="cadastro_footer_imagem"
                    alt="Logo Energia Fácil"
                    style={{ width: "400px" }}
                  />
                </div>
                <div className="background-blue cadastro_footer_texto">
                  <p className="paragraph-3">
                    Criado e desenvolvido por Energia Fácil
                  </p>
                </div>
              </footer>
            </main>
          </>
        )}
      </Formik>
    </>
  );
}
