import React from "react";

export default function Assinatura() {
  return (
    <>
      <section className="assinatura container mb-5">
        <div className="assinatura-texto col-xl-7 col-11 mb-5">
          <div className="d-flex align-items-center title-beneficios col-xl-11">
            <div className="beneficios-linha"></div>
            <h3 className="title-1">Como funciona a energia por assinatura?</h3>
          </div>
          {/* <h1 className="title-1">Como funciona a energia por assinatura?</h1> */}
          <p className="paragraph-4">
            Ao aderir a um plano que toma como base o seu consumo histórico,
            parte da energia gerada de uma usina limpa é injetada na sua unidade
            consumidora e se torna crédito para abater no seu consumo.
          </p>
        </div>
        <div className="row d-flex justify-content-center ">
          <div className="background-red card-red col-xl-3 col-md-6 col-5">
            <center className="card-body">
              <img
                src="/images/sun.svg"
                alt="Sun"
                className="mb-2"
                style={{ width: "45px" }}
              />
              <p className="text-white">
                Você adere a um plano sob medida para o seu consumo.
              </p>
            </center>
          </div>
          <div className="background-white card-white col-xl-3 col-md-6 col-5">
            <center className="card-body">
              <div className="circulo-cinza d-flex justify-content-center align-items-center">
                <img
                  src="/images/bolt.svg"
                  alt="Bolt"
                  style={{ width: "45px" }}
                />
              </div>
              <p className="text-black mt-3">
                Os créditos de energia são vinculados à sua unidade consumidora.
              </p>
            </center>
          </div>
          <div className="background-red card-red col-xl-3 col-md-6 col-5">
            <center className="card-body">
              <img
                src="/images/building.svg"
                alt="Card-1"
                className="mb-2"
                style={{ width: "45px" }}
              />
              <p className="text-white">
                Você visualiza na fatura da concessionária a energia compensada
                e paga apenas o custo de disponibilidade e iluminação pública.
              </p>
            </center>
          </div>
          <div className="background-white card-white col-xl-3 col-md-6 col-5">
            <center className="card-body">
              <div className="circulo-cinza d-flex justify-content-center align-items-center">
                <img
                  src="/images/batery.svg"
                  alt="Card-1"
                  style={{ width: "45px" }}
                />
              </div>
              <p className="text-black mt-3">
                Você recebe o relatório demonstrando a sua economia e paga a
                fatura com desconto.
              </p>
            </center>
          </div>
        </div>
      </section>
    </>
  );
}
