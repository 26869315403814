import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listaDistribuidoras } from '../../../../../pages/api/distribuidora'
import { obterSimulacao } from '../../../../../pages/api/simulacao'
import { Formik, Form, Field, useField } from 'formik'
import * as Yup from 'yup'
import { CalcularSimulador, EscolherPlano } from '../../../../../store'
import { Preco, EnergiaKWh } from '../../../../../components/control'
import numeral from '../../../../../components/control/numeralBRL'
import { useNavigate } from 'react-router-dom'
import Numeral from 'numeral'
import api from '../../../../../pages/api/api'
import { Input } from '../../../../../components/control/Input'

const SimuladorSchema = Yup.object().shape({
  idDistribuidora: Yup.string().required('Selecione uma distribuidora'),
  // valorConsumo: Yup.string().required('Informe o valor do consumo'),
})

const DistribuidoraSelect = ({ ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <select className="form-control" {...field} {...props} />
      {meta.touched && meta.error ? (
        <>
          <div style={{ marginTop: '.5rem' }}>{meta.error}</div>
        </>
      ) : null}
    </div>
  )
}

export default function Planos() {
  const fluxoConsorcio = useSelector((state) => state)
  const [distribuidoraCombo, setDistribuidoraCombo] = useState([])
  const [estado, setEstado] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    listaDistribuidoras().then(function (response) {
      setDistribuidoraCombo(response)
    })
  }, [])

  // useEffect(() => {
  //   if (fluxoConsorcio.consorciosCocaCola.passo === 1) {
  //     navigate('/adesao/dados-consorcio')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 2) {
  //     navigate('/adesao/documento-consorcio')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 3) {
  //     navigate('/adesao/termos')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 4) {
  //     navigate('/adesao/obrigado')
  //   } else if (fluxoConsorcio.consorciosCocaCola.passo === 0) {
  //     navigate('/')
  //   }
  // }, [fluxoConsorcio])

  const escolherPlanoClick = (
    periodo,
    opcao,
    idDistribuidora,
    valorConsumo,
    valor1Mes,
    valor12Mes,
    valorSemDesconto
  ) => {
    var valormes1= localStorage.getItem('valor1Mes');
    var valormes12 = localStorage.getItem('valor12Mes');
    var valorSemDesconto = localStorage.getItem('valorDesconto');
    var consumoMedio = localStorage.getItem('consumoMedio');


    // if(valormes1 != null && valormes12 != null && valorSemDesconto != null){      
      navigate('/adesao/dados-consorcio')
      dispatch(
        EscolherPlano({
          periodoContrato: periodo,
          opcao: opcao,
          idDistribuidora: idDistribuidora,
          valorConsumo: valorConsumo,
          valor1Mes: valor1Mes,
          valor12Mes: valor12Mes,
          valorSemDesconto: valorSemDesconto,
        })
      )
    //  }

    //     localStorage.setItem('valor1Mes', response.valor1Mes)
    //     localStorage.setItem('valor12Mes', response.valor12Mes)
    //     localStorage.setItem('valorDesconto', response.valorSemDesconto)
  }

  const [precoInput1, setPrecoInput1] = useState(0)
  const [precoInput2, setPrecoInput2] = useState(0)
  const [precoInput3, setPrecoInput3] = useState(0)
  const [consumokWhEstado, setConsumokWh] = useState(0)
  const [economiaMensal, setEconomiaMensal] = useState(0)
  const [economiaAnual12, setEconomiaAnual12] = useState(0)
  const [economiaAnual24, setEconomiaAnual24] = useState(0)
  const [economiaAnual36, setEconomiaAnual36] = useState(0)
  const [economiaCO2, setEconomiaCO2] = useState(0)
  const [slider, setSlider] = useState(0)

  const verificarWizard = () => {
    localStorage.setItem('wizard', true)
  }

  const funcaoPrecoInput = (e, distribuidora) => {
    var valor = e.target.value

    setSlider(parseInt(e.target.value))
    setConsumokWh(e.target.value)
    calcular(valor, distribuidora)
  }

  const calcular = (valor, distribuidora) => {
    var valorFormatado = parseFloat(valor);
    // if(valorFormatado >= 700 && valorFormatado <= 15000 && distribuidora != ""){
      obterSimulacao(distribuidora, valorFormatado).then(function (response) {
        // dispatch(
        //   CalcularSimulador({
        //     idDistribuidora: values.idDistribuidora,
        //     valorConsumo: values.valorConsumo,
        //     distribuidora: '',
        //     parametro1: response.p1,
        //     parametro2: response.p2,
        //     parametro3: response.p3,
        //     valor1Mes: response.valor1Mes,
        //     valor12Mes: response.valor12Mes,
        //     valorSemDesconto: response.valorSemDesconto,
        //   })
        // )
        setValor1Mes(response.economiaAtualMensal)
        setValor12Mes(response.economiaAtualAnual12)
        setValorSemDesconto(response.valorSemDesconto)

        localStorage.setItem('valor1Mes', response.economiaAtualMensal)
        localStorage.setItem('valor12Mes', response.economiaAtualAnual12)
        localStorage.setItem('valorDesconto', response.valorSemDesconto)
        localStorage.setItem('idDistribuidora', distribuidora)
        localStorage.setItem('consumoMedio', valorFormatado)
      })
    // }
  }

  const [valor1Mes, setValor1Mes] = useState(0)
  const [valor12Mes, setValor12Mes] = useState(0)
  const [valorSemDesconto, setValorSemDesconto] = useState(0)

  useEffect(() => {
    localStorage.removeItem('valor1Mes')
    localStorage.removeItem('valor12Mes')
    localStorage.removeItem('valorDesconto')
    localStorage.removeItem('idLeadTela')
    localStorage.removeItem('idDistribuidora')
    localStorage.removeItem('consumoMedio')
    localStorage.removeItem('wizard')
  }, [])

  // const slideValue = document.querySelector("span");
  // const inputSlider = document.querySelector("input");
  // inputSlider.oninput = (() => {
  //   let value = inputSlider.value;
  //   slideValue.textContent = value;
  // });

  // console.log(slider, "SLIDER")

  const fileType = [
    'image/svg+xml',
    'image/svg',
    'image/png',
    'image/jpeg',
    'image/gif',
  ]

  return (
    <Formik
      initialValues={{
        idDistribuidora: '2',
        valorConsumo: ''
      }}
      validationSchema={SimuladorSchema}
      onSubmit={(values, {resetForm}) => {
        // if (values.valorConsumo >= 700 && values.valorConsumo <= 15000) {
          setEconomiaAnual12(0)
          setValor1Mes(0)
          setValor12Mes(0)
          setValorSemDesconto(0)
          setSlider(0)
          // localStorage.removeItem('valor1Mes')
          // localStorage.removeItem('valor12Mes')
          // localStorage.removeItem('valorDesconto')
          // localStorage.removeItem('idDistribuidora')
          // localStorage.removeItem('consumoMedio')
          // localStorage.removeItem('wizard')
          // resetForm();
          // setDistribuidoraId(values.idDistribuidora);
          obterSimulacao(values.idDistribuidora, values.valorConsumo).then(
            function (response) {
              // dispatch(
              //   CalcularSimulador({
              //     idDistribuidora: values.idDistribuidora,
              //     valorConsumo: values.valorConsumo,
              //     distribuidora: '',
              //     parametro1: response.p1,
              //     parametro2: response.p2,
              //     parametro3: response.p3,
              //     valor1Mes: response.valor1Mes,
              //     valor12Mes: response.valor12Mes,
              //     valorSemDesconto: response.valorSemDesconto,
              //   })
              // )
              // console.log(response)
              setValor1Mes(response.economiaAtualMensal)
              setValor12Mes(response.economiaAtualAnual12)
              setValorSemDesconto(response.valorSemDesconto)

              localStorage.setItem('valor1Mes', response.economiaAtualMensal)
              localStorage.setItem('valor12Mes', response.economiaAtualAnual12)
              localStorage.setItem('valorDesconto', response.valorSemDesconto)
              localStorage.setItem('consumoMedio', values.valorConsumo)
              localStorage.setItem('idDistribuidora', values.idDistribuidora)
            }
          )
        // }
      }}>
      {({
        errors,
        handleSubmit,
        handleBlur,
        handleChange,
        setFieldValue,
        values,
      }) => (
        <Form>
          <section className="container" id="plano">
            <div className="plano row d-flex align-items-center">
              <div className="plano_caixa row col-xl-5 col-sm-11 col-md-11 col-lg-11 col-12">
                <div className=" calculadora d-flex align-items-center justify-content-center">
                  <center>
                    <h3 className="title-3 mb-2">Quer Economizar?</h3>
                    <label className="paragraph-2" htmlFor="">
                      Escolha a sua distribuidora
                    </label>
                    <br />
                    <div className="mt-2">
                      <DistribuidoraSelect
                        name="idDistribuidora"
                        className="select-plano"
                        onChange={(e) => {
                          setFieldValue('idDistribuidora', e.target.value)
                          handleSubmit()
                        }}>
                        <option value="">Selecione distribuidora</option>
                        {distribuidoraCombo.map((item) => (
                          <option value={item.idDistribuidora}>
                            {item.nomeDistribuidora}
                          </option>
                        ))}
                      </DistribuidoraSelect>
                    </div>
                    <br />
                    <div className="mb-2 mt-3">
                      <label className="paragraph-2">
                        Qual o seu consumo em kWh médio?
                      </label>
                      <br />
                      {slider >= 1 ? (
                        <Field
                          component={EnergiaKWh}
                          name="valorConsumo"
                          className="select-plano mb-2"
                          placeholder="0 KWh"
                          value={slider}
                          onChange={(e) => {
                            handleSubmit()
                          }}
                        />
                      ) : (
                        <Field
                          component={EnergiaKWh}
                          name="valorConsumo"
                          className="select-plano mb-2"
                          placeholder="0 KWh"
                          onChange={(e) => {
                            setSlider(e.target.value)
                            handleSubmit()
                          }}
                        />
                      )}
                      {/* <Field
                        component={EnergiaKWh}
                        name="valorConsumo"
                        className="select-plano mb-2"
                        placeholder="0 KWh"
                        value={slider}
                        // initialValues={...}
                        onChange={(handleSubmit)}
                        // onKeyUp={handleChange}
                        // onChange={(e) => {
                        //   handleSubmit()
                        // }}
                        // value={
                        //   consumokWhEstado == 700 ? 700 : consumokWhEstado
                        // }
                      /> */}
                    </div>
                    <div className="mt-1">
                      <input
                        type="range"
                        min="700"
                        max="15000"
                        className="slider"
                        onMouseUp={(e) =>
                          funcaoPrecoInput(e, values.idDistribuidora)
                        }
                        onChange={(e) => setSlider(e.target.value)}
                        style={{ cursor: 'grab', opacity: '1', width: '65%' }}
                      />
                      {/* <Field
                          name="valorConsumo"
                          className="select-plano"
                          value={
                            consumokWhEstado == 700 ? 700 : consumokWhEstado
                          }
                          component={EnergiaKWh}
                          disabled
                        /> */}
                    </div>

                    {/* <Field
                          name="valorConsumo"
                          className="select-plano"
                          value={
                            consumokWhEstado == 700 ? 700 : consumokWhEstado
                          }
                          component={EnergiaKWh}
                          disabled
                        /> */}
                    {/* <Field
                      name="valorConsumo"
                      className="d-none mb-2"
                      value={consumokWhEstado == 700 ? 700 : consumokWhEstado}
                      component={EnergiaKWh}
                      // disabled
                    /> */}
                    {/* <a
                      className="button"
                      onClick={() => handleSubmit(setEstado(true))}>
                      Calcular Economia
                    </a> */}
                  </center>
                </div>
              </div>
              <div className="caixa-plano-valores col-xl-6 col-sm-11 col-md-11 col-lg-11 col-12">
                <div>
                  <h2 className="title-plano-valores">Veja nossa proposta</h2>
                  <div className="plano-valores">
                    <div className="d-flex">
                      <div className="valores-caixa">
                        <p className="titulo-valores mb-1">Valor do plano</p>
                        <h3 className="paragraph-valores nowrap">
                          {'R$ ' + numeral(valor1Mes).format('0,0.00')}
                        </h3>
                      </div>
                      <div className="valores-caixa">
                        <p className="titulo-valores mb-1 nowrap">
                          Custo atual de energia
                        </p>
                        <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                          {'R$ ' + numeral(valorSemDesconto).format('0,0.00')}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="valores-caixa">
                        <p className="titulo-valores mb-1 ">
                          Contrato de{' '}
                          <strong className="text-bold">12 meses,</strong>{' '}
                          economia projetada de:
                        </p>
                        <h3 className="paragraph-valores">
                          {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                        </h3>
                      </div>
                      {/* <div className="valores-caixa col-5">
                        <p className="titulo-valores mb-1 ">
                          Você ainda contribui com até:
                        </p>
                        <h3 className="paragraph-valores">
                          {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                        </h3>
                      </div> */}
                      <div style={{ float: 'right' }}>
                        <a
                          className="button"
                          href="#cadastro1"
                          style={{ float: 'right' }}
                          onClick={() => {
                            verificarWizard();
                            escolherPlanoClick(
                              12,
                              1,
                              values.idDistribuidora,
                              values.valorConsumo,
                              valor1Mes,
                              valor12Mes,
                              valorSemDesconto
                            )
                          }}>
                          Contratar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  )
}
