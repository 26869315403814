import React from "react";

export default function Economia() {
  return (
    <>
      <section className="container ">
        <div className="row pt-5 economia d-flex align-items-center justify-content-center">
          {/* <div className="col-2 col-md-2 col-lg-2 col-xl-1">
            <blockquote className="large-container">
              <h2 className="title-1 section-title -vertical-text">Economia</h2>
            </blockquote>
          </div> */}
          <div className="col-11 col-md-8 col-xl-9 col-lg-8 col-sm-10 pl-5 ">
            <div className="d-flex align-items-center">
              <div className="beneficios-linha"></div>
              <h1 className="title-1">
                Qual a sua economia com nosso plano de energia por assinatura?
              </h1>
            </div>
            <p className="paragraph-4  pt-2">
              Além de reduzir a emissão de gás carbônico e fazer parte do
              movimento de energia renovável, a energia solar é capaz de reduzir
              em até 20% os custos com energia da sua empresa.
            </p>
          </div>
          <div className="col-sm-6 col-md-2 col-lg-2 col-xl-3 imagem-economia">
            <img
              className="img-fluid"
              src="images/handshake.svg"
              alt="Aperto de mão"
            />
          </div>
        </div>
        <div className="row background-black text-white p-md-5 ms-1">
          <div className="col-12 col-md-6 p-3 p-lg-0">
            <div className="row">
              <div className="col-0 col-lg-9">
                <b className="">
                  &nbsp;
                  <br />
                  &nbsp;
                </b>
              </div>
            </div>
            <div className="row">
              <div className="col-0 col-lg-3" />
              <div className="col-7 col-lg-5">
                <h3 className="text-white mb-2 economia-title">Antes</h3>
                <p>Tarifa comum cobrada pela concessionária</p>
              </div>
              <div className="col-5 col-lg-4">
                <img
                  className="img-fluid"
                  src="images/fatura1.svg"
                  alt="Ilustração fatura atual"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 p-3 ">
            <div className="row">
              <div className="col-0 col-lg-9">
                <br />
                <br />
              </div>
            </div>
            <div className="row">
              <div className=" col-4">
                <h3 className="text-white mb-2 economia-title">Depois</h3>
                <p>Você paga duas faturas mensalmente e economiza</p>
                <h3 className="text-white mt-3 economia-title">16,35%</h3>
                <p>de economia</p>
              </div>
              <div className="col-4">
                <img
                  className="img-fluid"
                  src="images/fatura2.svg"
                  alt="Ilustração consórcio"
                />
              </div>
              <div className="col-4">
                <img
                  className="img-fluid"
                  src="images/fatura3.svg"
                  alt="Ilustração fatura minima"
                />
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-4">
              <p className="paragraph-economia-footer mb-3 ms-4">
                Se houver bandeiras tarifárias semelhantes ao ano de 2021, sua
                economia pode passar de 30%
              </p>
            </div>
            <div className="col-lg-3 mb-3">
              <p className="valor-total">
                <strong>valor total R$ 2.220,52</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
