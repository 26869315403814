//Importação React
import React, { useState } from "react";
import data from "./data/data";
import SingleQuestion from "./data/questao";

export default function Perguntas() {
  const [questions, setQuestions] = useState(data);

  return (
    <>
      <section className="container d-flex justify-content-between align-items-center perguntas">
        <div className="row d-flex justify-content-center align-items-center">
          <h1 className="title-1 mb-4 col-sm-12 col-11">
            Perguntas frequentes
          </h1>

          <div className="col-xl-8 col-md-7 col-sm-12 col-11">
            <div className="info">
              {questions.map((question) => (
                <div className="d-flex align-items-center mb-2">
                  <div className="perguntas-linha me-3"></div>
                  <SingleQuestion key={question.id} {...question} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-xl-4 col-md-5 col-sm-7 col-11">
            <div className="card-perguntas-red">
              <h3>Tem alguma dúvida especifica?</h3>
              <p className="break-word">
                Entre em contato com a gente
                {/* contato@acessoenergia.com.br */}
                <br />
                contato@energiafacil.com.br
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
