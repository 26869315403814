import { useMemo } from "react";
import { createStore, applyMiddleware, compose } from "redux";
// import { routerMiddleware } from 'connected-react-router'
import thunk from "redux-thunk";
import * as History from "history";
import rootReducer from "./modules";
import { persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

// const dispatch = useDispatch();

function makeStore(initialState = consorcioInitialState) {
  return createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware())
  );
}

let store;

const consorcioInitialState = {
  passo: 0,
  idLead: null,
  idDistribuidora: null,
  distribuidora: "",
  periodoContrato: null,
  valorConsumo: null,
  cpfResponsavel: null,
  valor: null,
  percentual: null,
  nome: null,
  email: null,
  telefone: null,
  parametro1: null,
  parametro2: null,
  parametro3: null,
  valor1Mes: null,
  valor12Mes: null,
  valorSemDesconto: null,
  termoAdesao: null,
  strParametro7: null,
};

export const actionTypes = {
  Calcular: "Calcular",
  EscolherPlano: "EscolherPlano",
  EnviarInscricao: "EnviarInscricao",
  Inscrito: "Inscrito",
  EnviarDocumento: "EnviarDocumento",
  Finalizar: "Finalizar",
  Voltar: "Voltar",
};

export const consorcioCocaColaSlice = createSlice({
  name: "consorciosCocaCola",
  initialState: consorcioInitialState,
  reducers: {
    EscolherPlano: (state, action) => {
      state.valor1Mes = action.payload.valor1Mes;
      state.valor12Mes = action.payload.valor12Mes;
      state.valorSemDesconto = action.payload.valorSemDesconto;
      state.idDistribuidora = action.payload.idDistribuidora;
      // state.passo= 1;
    },
    // Voltar: (state, action) => {
    //   state.valor1Mes = action.payload.valor1Mes;
    //   state.valor12Mes = action.payload.valor12Mes;
    //   state.valorSemDesconto = action.payload.valorSemDesconto;
    //   state.passo= state.passo-1;
    // },
    EnviarInscricao: (state, action) => {
      state.nome = action.payload.nome;
      state.email = action.payload.email;
      state.telefone = action.payload.telefone;
      state.strParametro7 = action.payload.strParametro7;
      state.idLead = action.payload.idLead;
      // state.passo= 2;
    },
  },
});

const { actions } = consorcioCocaColaSlice;

// REDUCERS
export const reducer = (state = consorcioInitialState, action) => {
  switch (action.type) {
    case actionTypes.Calcular:
      return {
        ...state,
        idDistribuidora: action.payload.idDistribuidora,
        distribuidora: action.payload.distribuidora,
        valorConsumo: action.payload.valorConsumo,
        parametro1: action.payload.parametro1,
        parametro2: action.payload.parametro2,
        parametro3: action.payload.parametro3,
        valor1Mes: action.payload.valor1Mes,
        valor12Mes: action.payload.valor12Mes,
        valorSemDesconto: action.payload.valorSemDesconto,
      };
    case actionTypes.Inscrito:
      return {
        ...state,
        idLead: action.payload.idLead,
      };

    case actionTypes.EscolherPlano:
    //  actions.EscolherPlano(action)
    // return {
    //   ...state,
    //   // periodoContrato: action.payload.periodoContrato,
    //   // percentual: action.payload.opcao == 1 ? parseFloat(state.parametro1) : action.payload.opcao == 2 ? parseFloat(state.parametro2) : parseFloat(state.parametro3),
    //   // idDistribuidora: action.payload.idDistribuidora,
    //   // valorConsumo: action.payload.valorConsumo,
    //   passo: 1,
    //   valor1Mes: action.payload.valor1Mes,
    //   valor12Mes: action.payload.valor12Mes,
    //   valorSemDesconto: action.payload.valorSemDesconto,
    // }
    case actionTypes.EnviarDocumento:
      return {
        ...state,
      };
    case actionTypes.EnviarInscricao:
    // actions.EnviarInscricao(action)
    // return
    // {
    //   ...state,
    //   passo: 2,
    //   nome: action.payload.nome,
    //   email: action.payload.email,
    //   telefone: action.payload.telefone,
    //   strParametro7: action.payload.strParametro7,
    //   idLead: action.payload.idLead,
    // }
    case actionTypes.Finalizar:
      return {
        ...state,
        termoAdesao: action.payload.termoAdesao,
        passo: 3,
      };
    // case actionTypes.Voltar:
    //   return {
    //     ...state,
    //     voltar: true,
    //     passo: action.passo,
    //   }
    default:
      return state;
  }
};

// ACTIONS
export const CalcularSimulador = (payload) => (dispatch) => {
  dispatch(actions.CalcularSimulador(payload));
};
export const EscolherPlano = (payload) => (dispatch) => {
  dispatch(actions.EscolherPlano(payload));
};
export const EnviarDocumento = (payload) => {
  return { type: actionTypes.EnviarDocumento, payload };
};
export const EnviarInscricao = (payload) => (dispatch) => {
  dispatch(actions.EnviarInscricao(payload));
};
export const AtualizarIdLead = (payload) => {
  return { type: actionTypes.Inscrito, payload };
};
export const Finalizar = (payload) => {
  // return { type: actionTypes.Finalizar, payload }
  // dispatch(actions.EnviarInscricao(payload))
};
// export const Voltar = (passo) => dispatch => {
//   dispatch(actions.Voltar(passo))
// }

const persistConfig = {
  key: "primary",
  storage,
  whitelist: ["consorcioEnergiaEdilson"], // place to select which state you want to persist
};

const persistedReducer = persistReducer(persistConfig, reducer);

// function makeStore(initialState = consorcioInitialState) {
//   return createStore(
//     persistedReducer,
//     initialState,
//     composeWithDevTools(applyMiddleware())
//   );
// }

export const initializeStore = (preloadedState) => {
  let _store = makeStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = makeStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
