import {config} from "../Config";
 export default function middlewareAxios(axios, store) {
   axios.interceptors.request.use(
     config => {
       const {
         auth: { authToken }
       } = store.getState();
       if (authToken) {
         config.headers.Authorization = `Bearer ${authToken}`;
       }
         config.headers['Access-Control-Allow-Origin'] = '*';
       return config;
     },
     err => {
         Promise.reject(err);
     }
   );

         axios.interceptors.response.use(
             (response) => response,
             (error) => {
                if(error.response.status==401)
                {
                    return window.location.href = config.url.urlLogout;
                }
             }
         );

}
