//Importação React
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  incluirSimulacaoLead,
  alterarSimulacaoLeadv1,
} from "../../../../../pages/api/simulacaoLead";
import { Voltar, EnviarInscricao } from "../../../../../store";
import { Telefone } from "../../../../../components/control";
import "../cadastro.css";
import { Input } from "../../../../../components/control/Input";
import EconomiaTotal from "../components/EconomiaTotal";
import numeral from "../../../../../components/control/numeralBRL";
import { retirarMascaraTelefone } from "../../../../../components/control/formatarCampos";

const AdesaoFormSchema = Yup.object().shape({
  nome: Yup.string().required("Nome é obrigatório"),
  email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  telefone: Yup.string().required("Telefone é obrigatório"),
  strParametro7: Yup.string().required("Celular é obrigatório"),
});

// const nomeRequired = (message) => <p className="help is-danger text-red">{message}</p>;

export default function Cadastro1() {
  const fluxoConsorcio = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [valor1Mes, setValor1Mes] = useState(0);
  const [valor12Mes, setValor12Mes] = useState(0);
  const [valorSemDesconto, setValorSemDesconto] = useState(0);
  const [idDistribuidora, setIdDistribuidora] = useState();
  const [consumoMedio, setConsumoMedio] = useState();
  const [idLocal, setIdLocal] = useState(null);

  useEffect(() => {
    if (
      fluxoConsorcio.consorciosCocaCola.valor1Mes != 0 &&
      fluxoConsorcio.consorciosCocaCola.valor12Mes != 0 &&
      fluxoConsorcio.consorciosCocaCola.valorSemDesconto != 0
    ) {
      // setValor1Mes(fluxoConsorcio.consorciosCocaCola.valor1Mes)
      // setValor12Mes(fluxoConsorcio.consorciosCocaCola.valor12Mes)
      // setValorSemDesconto(fluxoConsorcio.consorciosCocaCola.valorSemDesconto)
      // var obj ={
      //   valor1Mes: fluxoConsorcio.consorciosCocaCola.valor1Mes,
      //   valor12Mes:fluxoConsorcio.consorciosCocaCola.valor12Mes,
      //   valorSemDesconto:fluxoConsorcio.consorciosCocaCola.valorSemDesconto
      // }
      // setEstado(obj);
    }

    const wizard = localStorage.getItem("wizard");

    if (wizard !== "true") {
      window.location.href = "https://energiafacil.com.br/";
    }

    var valormes1 = localStorage.getItem("valor1Mes");
    var valormes12 = localStorage.getItem("valor12Mes");
    var valorSemDesconto = localStorage.getItem("valorDesconto");
    var idDistribuidora = localStorage.getItem("idDistribuidora");
    var consumoMedio = localStorage.getItem("consumoMedio");
    var formatado1 = parseFloat(valormes1);
    var formatado2 = parseFloat(valormes12);
    var formatado3 = parseFloat(valorSemDesconto);
    var formatado5 = parseInt(consumoMedio);

    setValor1Mes(formatado1);
    setValor12Mes(formatado2);
    setValorSemDesconto(formatado3);
    setIdDistribuidora(idDistribuidora);
    setConsumoMedio(formatado5);
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          nome: "",
          email: "",
          telefone: "",
          strParametro7: "",
        }}
        enableReinitialize={true}
        validationSchema={AdesaoFormSchema}
        onSubmit={(values) => {
          values.telefone = retirarMascaraTelefone(values.telefone);
          values.strParametro7 = retirarMascaraTelefone(values.strParametro7);
          // if (fluxoConsorcio.consorciosCocaCola.idLead == null) {
          incluirSimulacaoLead(
            values.nome,
            values.email,
            values.telefone,
            values.strParametro7,
            idDistribuidora,
            valorSemDesconto,
            consumoMedio,
            1
          ).then(function (response) {
            var id = response.id;
            localStorage.setItem("idLeadTela", id);
            setIdLocal(id);
            dispatch(
              EnviarInscricao({
                nome: values.nome,
                email: values.email,
                telefone: values.telefone,
                strParametro7: values.strParametro7,
                idLead: parseInt(response.id),
              })
            );
            navigate("/adesao/documento-consorcio");
          });
          // }
          // else {
          //   alterarSimulacaoLeadv1(
          //     fluxoConsorcio.consorciosCocaCola.idLead,
          //     values.nome,
          //     values.email,
          //     values.telefone,
          //     values.strParametro7,
          //     idDistribuidora,
          //     valorSemDesconto,
          //     consumoMedio
          //   ).then(function (response) {
          //     navigate('/adesao/documento-consorcio')
          //     dispatch(
          //       EnviarInscricao({
          //         nome: values.nome,
          //         email: values.email,
          //         telefone: values.telefone,
          //         strParametro7: values.strParametro7,
          //         idLead: fluxoConsorcio.consorciosCocaCola.idLead,
          //       })
          //     )
          //   })
          // }
        }}
      >
        {({ handleSubmit, errors, setFieldValue }) => (
          <>
            <header
              className="header p-3 d-flex align-items-center"
              id="cadastro1"
            >
              <div className="ms-3 me-4">
                <a href="/">
                  <img
                    src="/images/energiaFacil.svg"
                    alt="Logo Energia Fácil"
                    style={{ width: "250px" }}
                  />
                </a>
              </div>
              <div class="progress_1">
                <div class="progress_bar_1"></div>
              </div>
            </header>
            <main class="cadastro_background">
              <section class="container cadastro">
                <div class="row">
                  <div class="card cadastro_fatura col-xl-7 col-11 col-sm-12">
                    <div class="card-body">
                      <h3 class="cadastro_titulo">
                        Preencha os dados e entraremos em contato
                      </h3>
                      <Form className="form">
                        <div className="form-group">
                          <label className="cadastro_label mt-2">Nome</label>
                          <Field
                            component={Input}
                            name="nome"
                            className="form-control "
                            placeholder="Insira seu nome"
                          />
                          {/* <ErrorMessage name="nome" render={nomeRequired} /> */}
                        </div>
                        <div className="form-group mt-2">
                          <label className="cadastro_label">Email</label>
                          <Field
                            component={Input}
                            name="email"
                            className="form-control"
                            placeholder="Insira seu e-mail"
                          />
                          {/* <ErrorMessage name="email" render={nomeRequired} /> */}
                        </div>
                        <div className="form-group mt-2">
                          <label className="cadastro_label">Telefone</label>
                          <Field
                            component={Telefone}
                            name="telefone"
                            className="form-control"
                            placeholder="Insira seu telefone"
                          />
                          {/* <ErrorMessage name="telefone" className="text-red" /> */}
                        </div>
                        <div className="form-group mt-2">
                          <label className="cadastro_label">Celular</label>
                          <Field
                            component={Telefone}
                            name="strParametro7"
                            className="form-control"
                            placeholder="Insira seu celular"
                          />
                          {/* <ErrorMessage name="celular" render={nomeRequired} /> */}
                        </div>
                        <button
                          type="button"
                          className="cadastro_btn d-flex justify-content-center align-items-center"
                          style={{ float: "right" }}
                          href="#cadastro2"
                          onClick={() => handleSubmit()}
                        >
                          Continuar
                        </button>
                      </Form>
                    </div>
                  </div>
                  <div class="card cadastro_economia col-xl-4 col-11 col-sm-12">
                    <div class="card-body">
                      <h3 class="cadastro_titulo">Economia Atual</h3>
                      <p class="mb-1">
                        Contrato de{" "}
                        <strong className="text-bold">12 meses</strong> economia
                        projetada de
                      </p>
                      <h3 className="paragraph-valores nowrap">
                        {"R$ " + numeral(valor12Mes).format("0,0.00")}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Benefício</p>
                      <h3 className="paragraph-valores nowrap">19%</h3>
                      <p className="titulo-valores mt-4 mb-1">
                        Valor atual da energia
                      </p>
                      <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                        {"R$ " + numeral(valorSemDesconto).format("0,0.00")}
                      </h3>
                      <p className="titulo-valores mt-4 mb-1">Valor do plano</p>
                      <h3 className="paragraph-valores nowrap mb-2">
                        {"R$ " + numeral(valor1Mes).format("0,0.00")}
                      </h3>
                    </div>
                  </div>
                  {/* <EconomiaTotal estado={estado}/> */}
                </div>
              </section>
              <footer>
                <div className="background-gray-darker">
                  <img
                    src="/images/logo-white.svg"
                    className="cadastro_footer_imagem"
                    alt="Logo Energia Fácil"
                    style={{ width: "400px" }}
                  />
                </div>
                <div className="background-blue cadastro_footer_texto">
                  <p className="paragraph-3">
                    Criado e desenvolvido por Energia Fácil
                  </p>
                </div>
              </footer>
            </main>
          </>
        )}
      </Formik>
    </>
  );
}
