//Importação React
import React from "react";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="background-gray-darker">
          <div className="caixa-footer row d-flex justify-content-center align-items-center">
            <div className="background-black col-xl-4 col-lg-6 col-md-5 col-sm-6 col-11">
              <img
                src="/images/logo-white.svg"
                alt="Logo Energia Fácil"
                // className="w-28"
                style={{ width: "70%" }}
              />
              <p className="text-white">
                A Energia Fácil desenvolveu uma solução para redução de custo de
                energia de uma maneira simples e com energia limpa e renovável,
                desburocratizando o acesso a energia.
              </p>
              <nav className="redes-sociais">
                <a href="#" class="hvr-float">
                  <i class="mdi mdi-facebook"></i>
                </a>
                <a href="#" class="hvr-float">
                  <i class="mdi mdi-linkedin"></i>
                </a>
                <a href="#" class="hvr-float">
                  <i class="mdi mdi-instagram"></i>
                </a>
              </nav>
            </div>
            <div className="textos col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12 row d-flex justify-content-center">
              {/* <div className="institucional col-xl-5 col-md-5 col-sm-5 col-5"> */}
              {/* <h3 className="text-white mb-3">Institucional</h3> */}
              {/* <ul>
                  <li className="mb-2">
                    <a href="#" className="link-text">
                      Quem Somos
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="mb-2">
                    <a href="#" className="link-text">
                      Contato
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="mb-2">
                    <a href="#" className="link-text">
                      Politica de Privacidade
                    </a>
                  </li>
                </ul> */}
              {/* </div> */}
              <div className="solucoes col-xl-4 col-lg-4 col-md-3 col-sm- col-6">
                <h3 className="text-white mb-2 nowrap">Entre em contato</h3>
                {/* <ul>
                  <li className="mb-2">
                    <a href="#" className="link-text">
                      Redução de tarifa
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="mb-4">
                    <a href="#" className="link-text">
                      Redução de consumo
                    </a>
                  </li>
                </ul> */}
                <div className="contato mt-3">
                  <ul>
                    <li className="mb-2">
                      <a
                        href="mailto:contato@energiafacil.com.br"
                        className="link-text"
                      >
                        contato@energiafacil.com.br
                      </a>
                    </li>
                  </ul>
                  <ul>
                    {/* <li className="mb-2">
                      <a href="#" className="link-text">
                        +55 11 97027-5838
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="background-blue">
            <p className="paragraph-3">
              Criado e desenvolvido por Energia Fácil
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
