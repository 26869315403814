import CookieConsent, { Cookies } from "react-cookie-consent";

import React, { useEffect, useState } from "react";
import Footer from "../../../../layout/components/Footer";
import Header from "../../../../layout/components/Header";
import Assinatura from "./components/Assinatura";
import Beneficios from "./components/Beneficios";
import Economia from "./components/Economia";
import Escolha from "./components/Escolha";
import Landing from "./components/Landing";
import Perguntas from "./components/Perguntas";
import Planos from "./components/Planos";
import Modal from "../../../../components/modal/Modal";

export default function Main() {
  return (
    <>
      {/* <Modal /> */}
      <CookieConsent
        location="bottom"
        buttonText="Aceitar Cookies"
        declineButtonText="Não aceitar Cookies"
        enableDeclineButton
        cookieName="CookieName"
        style={{ background: "#fff", borderTop: "1px solid #000" }}
        buttonStyle={{ fontSize: "13px", borderRadius: "10px" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "10px" }}
        contentStyle={{ color: "#000" }}
        expires={150}
      >
        Usamos cookies para permitir que o nosso website funcione corretamente, para personalizar conteúdo e anúncios, para proporcionar funcionalidades das redes sociais e para analisar o nosso tráfego.
        Também partilhamos informação com os nossos parceiros das redes sociais, de publicidade e de analítica sobre o seu uso do nosso website.
      </CookieConsent>
      <Header />
      <Landing />
      <Beneficios />
      <Planos />
      <Assinatura />
      <Escolha />
      <Economia />
      <Perguntas />
      <Footer />
    </>
  );
}
