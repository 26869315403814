// import api from './api';
import axios from "axios";
import { config } from "../../Config";
export const baseURL = config.url.apiEndPoint;

const api = axios.create();

export function incluirSimulacaoLead(nome, email, telefone, strParametro7,intParametro1,decParametro3,decParametro4,tipoParceiro) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/simulacao/cocacola/lead/incluir`, {
      nome,
      email,
      telefone,
      strParametro7,
      intParametro1,
      decParametro3,
      decParametro4,
      tipoParceiro
    });
    resolve(response.data.entities);
  });
};

export function alterarSimulacaoLeadv1(idLead, nome, email, telefone, strParametro7,intParametro1,decParametro3,decParametro4) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/simulacao/cocacola/lead/alterar_v1`, {
      idLead,
      nome,
      email,
      telefone,
      strParametro7,
      intParametro1,
      decParametro3,
      decParametro4
    });
    resolve(response.data.entities);
  });
};

export function alterarSimulacaoLead(idLead, strParametro5, strParametro6, faturaURL, faturaPath, nomeArquivo ) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/simulacao/cocacola/lead/alterar`, {
      idLead,
      strParametro5,
      strParametro6,
      faturaURL,
      faturaPath,
      nomeArquivo
    })
    resolve(response.data.entities);
  });
};

export function termoAdesaoSimulacaoLead(ids,situacao) {
  return new Promise(async (resolve, reject) => {
    const response = await api.post(`${baseURL}/simulacao/cocacola/lead/termoAdesao`, {
      ids,
      situacao
    });
    resolve(response.data.entities);
  });
};
