//Importação React
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EconomiaTotal from "../components/EconomiaTotal";
import "../cadastro.css";
import numeral from "../../../../../components/control/numeralBRL";

export default function Cadastro4() {
  const fluxoConsorcio = useSelector((state) => state);

  const [valor1Mes, setValor1Mes] = useState(0);
  const [valor12Mes, setValor12Mes] = useState(0);
  const [valorSemDesconto, setValorSemDesconto] = useState(0);

  useEffect(() => {
    // if(fluxoConsorcio.consorciosCocaCola.valor1Mes  !=0 && fluxoConsorcio.consorciosCocaCola.valor12Mes !=0 && fluxoConsorcio.consorciosCocaCola.valorSemDesconto !=0 ){
    //   setValor1Mes(fluxoConsorcio.consorciosCocaCola.valor1Mes);
    //   setValor12Mes(fluxoConsorcio.consorciosCocaCola.valor12Mes);
    //   setValorSemDesconto(fluxoConsorcio.consorciosCocaCola.valorSemDesconto);
    // }

    var valormes1 = localStorage.getItem("valor1Mes");
    var valormes12 = localStorage.getItem("valor12Mes");
    var valorSemDesconto = localStorage.getItem("valorDesconto");
    var formatado1 = parseFloat(valormes1);
    var formatado2 = parseFloat(valormes12);
    var formatado3 = parseFloat(valorSemDesconto);

    const wizard = localStorage.getItem("wizard");

    if (wizard !== "true") {
      window.location.href = "http://localhost:3000/";
    }

    setValor1Mes(formatado1);
    setValor12Mes(formatado2);
    setValorSemDesconto(formatado3);
  }, []);

  return (
    <>
      <header className="header p-3 d-flex align-items-center">
        <div className="ms-3 me-4">
          <a href="/">
            <img
              src="/images/energiaFacil.svg"
              alt="Logo Energia Fácil"
              style={{ width: "250px" }}
            />
          </a>
        </div>
        <div class="progress_4">
          <div class="progress_bar_4"></div>
        </div>
      </header>
      <main class="cadastro_background">
        <section class="container cadastro">
          <div class="row justify-content-center">
            <div class="card cadastro_fatura col-xl-7 col-11 col-sm-12">
              <div class="card-body">
                <h3 class="termos_titulo mt-3">Obrigado</h3>
                <h3 class="cadastro_titulo mt-1">
                  Em breve entraremos em contato para finalizar a contratação
                </h3>
                <img
                  // src="/images/social-event.svg"
                  // src="/images/client-meeting.svg"
                  // src="/images/success.svg"
                  src="/images/finished-illustration.svg"
                  alt=""
                  style={{ width: "60%" }}
                />
                <p className="cadastro_contato">
                  Em caso de dúvida entre em contato com a gente
                </p>
                <a
                  href="mailto:contato@energiafacil.com.br"
                  class="cadastro_contato"
                >
                  <u>contato@energiafacil.com.br</u>
                </a>
              </div>
            </div>
            {/* <div class="card cadastro_economia col-xl-4 col-11 col-sm-12">
              <div class="card-body">
                <h3 class="cadastro_titulo">Economia Atual</h3>
                <p class="mb-1">
                  Contrato de <strong className="text-bold">12 meses</strong>{' '}
                  economizar projetada de
                </p>
                <h3 className="paragraph-valores nowrap">
                  {'R$ ' + numeral(valor12Mes).format('0,0.00')}
                </h3>
                <p className="titulo-valores mt-4 mb-1">Benefício</p>
                <h3 className="paragraph-valores nowrap">19%</h3>
                <p className="titulo-valores mt-4 mb-1">
                  Valor atual da energia
                </p>
                <h3 className="paragraph-valores text-decoration-line-through text-gray-darker nowrap">
                  {'R$ ' + numeral(valorSemDesconto).format('0,0.00')}
                </h3>
                <p className="titulo-valores mt-4 mb-1">Valor do plano</p>
                <h3 className="paragraph-valores nowrap mb-2">
                  {'R$ ' + numeral(valor1Mes).format('0,0.00')}
                </h3>
              </div>
            </div> */}
            {/* <EconomiaTotal/> */}
          </div>
        </section>
        <footer>
          <div className="background-gray-darker">
            <img
              src="/images/logo-white.svg"
              className="cadastro_footer_imagem"
              alt="Logo Energia Fácil"
              style={{ width: "400px" }}
            />
          </div>
          <div className="background-blue cadastro_footer_texto">
            <p className="paragraph-3">
              Criado e desenvolvido por Energia Fácil
            </p>
          </div>
        </footer>
      </main>
    </>
  );
}
