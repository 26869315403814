import React from "react";

export default function Escolha() {
  return (
    <>
      <section className="container escolha">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="escolha-imagem col-xl-5 col-md-5 col-sm-9 col-11 d-flex justify-content-center">
            <img
              src="/images/analyze.svg"
              style={{ width: "70%" }}
              alt="escolha"
            />
          </div>
          <div className="escolha-texto col-xl-5 col-md-6 col-sm-9 col-10">
            {/* <h1 className="title-1 mb-2">
              Fazer a escolha certa nem sempre é difícil.
            </h1> */}
            <div className="d-flex align-items-center mb-3">
              <div className="beneficios-linha"></div>
              <h3 className="title-1">
                Fazer a escolha certa nem sempre é difícil.
              </h3>
            </div>
            <p className="paragraph-4">
              Você deixa de emitir gás carbônico (CO2) todos os anos,
              contribuindo para a redução do efeito estuda e ajuda a preservar a
              natureza do Mato Grosso e do mundo.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
