const prod = {
  url: {
      apiEndPoint: 'https://solarbr.matogrossoenergia.com.br/api/v2',
      urlLogout: 'https://app.rzkenergia.com.br/logout',
      urlUpload: 'https://solarbr.matogrossoenergia.com.br/api',

  }
};
const dev = {
  url: {
      apiEndPoint: 'http://localhost:8181/api/v2',
      urlLogout: 'http://localhost:3000/logout',
      urlUpload: 'http://localhost:8181/api',
  }
};
export const config = prod;