import axios from "axios";
import { config } from "../../Config";
export const baseURL = config.url.apiEndPoint;

const api = axios.create();

// export function listaDistribuidoras() {
//   return new Promise((resolve, reject) => {
//     api.get('api/select/distribuidora').then(response => {
//       resolve(response.data.entities);
//     });
//   });
// };

export async function listaDistribuidoras() {
  return new Promise(async (resolve, reject) => {
    const response = await api.get(`${baseURL}/select/distribuidoraSimulador/cocacola`);
    resolve(response.data.entities);
  });
};
