import React from "react";

export default function Beneficios() {
  return (
    <>
      <section className="container mb-5">
        <div className="beneficio-texto">
          <div className="row d-flex justify-content-center">
            <div className="d-flex align-items-center title-beneficios col-xl-11 ms-4">
              <div className="beneficios-linha"></div>
              <h3 className="title-2">
                Principais benefícios da energia por assinatura
              </h3>
            </div>
            <div className="caixa-texto col-xl-5 col-10 d-flex align-items-center">
              {/* <div className=" ms-1 me-3">
                <img src="/images/4.svg" style={{ width: '65px' }} alt="" />
              </div> */}
              <div>
                <h4 className=" title-3">Energia limpa</h4>
                <p className="paragraph-1">
                  Economize no presente, preserve no futuro. Ao utilizar energia
                  oriunda de fontes renováveis você contribui para um mundo
                  melhor e ainda poderá compensar créditos em sua fatura,
                  economizando mensalmente
                </p>
              </div>
            </div>

            <div className="caixa-texto col-xl-5 col-10 d-flex align-items-center">
              {/* <div className="me-3">
                <img src="/images/1.svg" style={{ width: '75px' }} alt="" />
              </div> */}
              <div>
                <h4 className="mb-1 title-3">Sem fidelidade</h4>
                <p className="paragraph-1">
                  Você decide quando sair, mas nós construiremos uma jornada
                  duradoura com transparência e resultado.
                </p>
              </div>
            </div>
            <div className="caixa-texto col-xl-5 col-10 d-flex align-items-center">
              {/* <div className="ms-1 me-3">
                <img src="/images/3.svg" style={{ width: '65px' }} alt="" />
              </div> */}
              <div>
                <h4 className="mb-2 title-3">Mais dinheiro no seu bolso</h4>
                <p className="paragraph-1">
                  Quem participa de um de nossos planos fica isento do pagamento
                  da bandeira tarifária, é mais economia e dinheiro para você
                  usar como quiser.
                </p>
              </div>
            </div>
            <div className="caixa-texto col-xl-5 col-10 d-flex align-items-center">
              {/* <div className="ms-3 me-3">
                <img src="/images/2.svg" style={{ width: '45px' }} alt="" />
              </div> */}
              <div>
                <h4 className="mb-2 title-3">
                  Ganhe com o consórcio, facilmente
                </h4>
                <p className="paragraph-1">
                  Caso você não utilize toda a energia, o excedente é enviado
                  para a rede elétrica, gerando créditos de energia.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-end">
                <h3 className="rotated title-1 nowrap">Sobre a energia</h3>
      
            </div> */}
        </div>
      </section>
    </>
  );
}
