const questions = [
  {
    id: 1,
    title: "O QUE É UM CONSÓRCIO DE ENERGIA?",
    info: "Nosso propósito é oferecer economia aos consumidores de energia elétrica do Mercado Cativo através do sistema de compensação por meio da Geração Distribuída. Assim, através de nossas plataformas e parcerias, conectamos usinas de geração de energia limpa e renovável aos pontos que possuem interesse em gerar sua própria energia e economizar dentro da sistemática da Geração Distribuída, dando oportunidade para a utilização de créditos de energia por pessoas físicas e jurídicas no consumo de energia em suas residências e empresas",
  },
  {
    id: 2,
    title: "COMO É POSSÍVEL ADERIR A UM CONSÓRCIO MATO?",
    info: "A concretização desse mecanismo se dá através da adesão pelos consumidores às estruturas existentes para tal finalidade, tais como consórcios, cooperativas e associações civis, tudo em conformidade com Resoluções Normativas da Agência Nacional de Energia Elétrica (ANEEL) e legislação aplicável, especialmente a Lei nº 14.300/2002.",
  },
  {
    id: 3,
    title: "QUANTO CUSTA PARA ADERIR?",
    info: "Não cobramos taxa de adesão e não é necessário investir em equipamento ou instalação.",
  },
  {
    id: 4,
    title: "QUAL O PRAZO DO CONTRATO?",
    info: "Nossos contratos são celebrados por um ano e com possibilidade de renovação mas, como não cobramos fidelidade, você poder sair a qualquer momento, desde que nos avise com antecedência de no mínimo 90 dias.",
  },
  {
    id: 5,
    title: "COMO A ENERGIA É FORNECIDA?",
    info: "Através de fontes renováveis de energia são injetados créditos em kWh na rede da distribuidora destinado a(s) sua(s) unidade(s) consumidora(s) e a compensação mensal será visualizada na sua fatura da concessionária.",
  },
  {
    id: 6,
    title: "ENTÃO EU CONTINUO PAGANDO A CONCESSIONÁRIA?",
    info: "Sim, continua pagando a taxa mínima de disponibilidade e iluminação pública e eventuais excedentes de consumo.",
  },
  {
    id: 7,
    title:
      "PRECISO AVISAR A CONCESSIONÁRIA QUE MINHA ENERGIA VIRÁ DE OUTRA FONTE?",
    info: "Não é necessário. Cuidamos de todos os procedimentos necessários para sua comodidade.",
  },
  {
    id: 8,
    title: "COMO EU PAGO POR ESSA ENERGIA FORNECIDA?",
    info: "Nós disponibilizaremos a você um demonstrativo de economia e um boleto com a parcela da energia injetada e compensada já com o desconto.",
  },
  {
    id: 9,
    title:
      "SE NÃO PRECISO INVESTIR E PAGAR TAXA DE ENTRADA, COMO ESSA ENERGIA PODE SER MAIS BARATA?",
    info: "Você é beneficiado com a compensação de energia elétrica através da Geração Distribuída, prevista pelas resoluções da Agência Nacional de Energia Elétrica nº 482/2012 687/2015 e a legislação aplicável, especialmente a Lei nº 14.300/2022. Os custos neste caso são menores porque você receberá créditos direto da usina geradora, ou seja, você participa da geração da energia que proporcionam os créditos.",
  },
  {
    id: 10,
    title: "POR QUE EU NÃO PAGAREI MAIS AS BANDEIRAS TARIFÁRIAS?",
    info: "Porque quando sua unidade consumidora recebe créditos de uma fonte de energia renovável, você fica isento das bandeiras que têm em sua origem o fornecimento de energia térmica, não participando, portanto, do rateio de custos desta fonte que é mais cara. Além disso, você contribui com o planeta e ainda economiza.",
  },
  {
    id: 11,
    title:
      "E SEU MEU CONSUMO DE ENERGIA EM ALGUM MÊS FOR MAIOR, VAI FALTAR ENERGIA?",
    info: "Se o que injetarmos no mês não for suficiente para compensar todo o seu consumo, você não ficará sem energia, apenas pagará esse excedente à própria concessionária.",
  },
  {
    id: 12,
    title: "E SE FOR MENOR?",
    info: "Caso seu consumo em determinado mês for menor, nós cobraremos apenas a parcela da energia compensada e o excedente se torna crédito para compensação no(s) próximo(s) mês(es).",
  },
  {
    id: 13,
    title: "E SE EU FOR GERAR MINHA PRÓPRIA ENERGIA NO FUTURO?",
    info: "Atendemos apenas unidades consumidoras que não possuem geração própria, sendo esta uma condição fundamental para manutenção do serviço. Nesse sentido, se o usuário planejar a implantação de unidade de geração própria ele precisará informar de forma antecipada, com prazo mínimo de 90 dias a necessidade de seu desligamento.",
  },
  {
    id: 14,
    title: "COMO DEFINIMOS A ENERGIA PARA VOCÊ?",
    info: "Através do fornecimento da última fatura de energia da(s) sua(s) unidade(s) consumidora(s) com o histórico de consumo, nós calcularemos o volume de energia mensal e anual necessário para seu primeiro ano de contrato e apresentaremos a economia. ",
  },
  {
    id: 15,
    title: "COMO CALCULAR A MINHA ECONOMIA?",
    info: "Além disso, em nossas plataformas você também poderá simular a economia mensal e anual informando sua média de consumo em kWh mês, sendo essa informação de inteira responsabilidade do usuário. Uma vez informado o consumo e a concordância com as condições do CONSÓRCIO, poderemos, com o intuito de reduzir o tempo mínimo necessário de ingresso, preparar o contrato de adesão para apreciação e fechamento.",
  },
];
export default questions;
